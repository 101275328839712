<template>
    <div class="container" style="height: 100vh; width: 100vw;">
      <div class="header" style="margin-top: 10px;">
          <img :src="HeaderLogo" alt="HeaderLogo" class="header-logo" />
          </div>
      <div class="card-container d-flex justify-content-center align-items-center">
        <div class="card" style="width: 700px;">
        <div class="card-body">
          <h2 class="card-title text-center mb-4">Datos Bancarios</h2>
          <div class="row" style="text-align: left;">
            <div class="col-md-4 mb-3" >
              <label for="bankName" >Nombre del Banco:</label>
            </div>
            <div class="col-md-8 mb-3">
              <input type="text" class="form-control" id="bankName" v-model="bankName" :readonly="!profileEditMode">
            </div>
            <div class="col-md-4 mb-3">
              <label for="lastName">Numero de cuenta:</label>
            </div>
            <div class="col-md-8 mb-3">
              <input type="text" class="form-control" id="lastName" v-model="bankAccount" :readonly="!profileEditMode">
            </div>
          </div>
          <div v-if="!isMobile()">
            <div v-if="!profileEditMode">
            <button type="button" class="btn btn-secondary" style="margin-right: 10px;" @click="goToStudentDashboard">Regresar</button>
            <button type="button" class="btn btn-primary" style="margin-right: 10px; margin-left: 10px;" @click="editProfile">Editar datos bancarios</button>
          </div>
            <div v-else>
                <button type="button" class="btn btn-primary" style="margin-right: 10px;" @click="saveProfile">Guardar</button>
                <button type="button" class="btn btn-secondary" style="margin-left: 10px;" @click="cancelEdit">Cancelar</button>
            </div>
          </div>
          <div v-if="isMobile()">
            <div v-if="!profileEditMode">
            <button type="button" class="btn btn-primary" style="width: 60%; height: 40px;" @click="editProfile">Editar datos bancarios</button>
          </div>
            <div v-else class="d-flex flex-row justify-content-between">
                <button type="button" class="btn btn-primary" style="width: 45%; height: 40px;" @click="saveProfile">Guardar</button>
                <button type="button" class="btn btn-secondary" style="width: 45%; height: 40px;" @click="cancelEdit">Cancelar</button>
            </div>
            <div>
              <button type="button" class="btn btn-secondary" style="width: 60%; height: 40px; margin-top: 20px;" @click="goToStudentDashboard">Regresar</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  </template>

<div class="text-center">
      </div>
  
  
  <script>
  import axios from "axios";
  import { mapState, mapActions } from "vuex";
  
  export default {

    computed: {
  ...mapState(["tutorEmail", "isSubscribedTutor", "tutorId"]),
},

    data() {
      return {
        bankName: "",
        bankAccount: "",
        profileEditMode: false,
        userImage: require('@/assets/user.png'),
        HeaderLogo: require('@/assets/Mathpaas-white.png')
      };
    },
    async created() {
      try {
        // Fetch the authenticated user's email
        const response = await axios.get(`${this.$baseURL}/api/users/tutor-data?email=${this.tutorEmail}`);
  
        if (response.data.success) {
          const tutor = response.data.data;
          this.bankName = tutor.bank_name;
          this.bankAccount = tutor.bank_account;
        }
      } catch (error) {
        console.error("Error fetching bank data:", error);
      }
    },

    methods: {

      isMobile() {
    return window.innerWidth <= 768; // Adjust this value based on your needs
  },

      ...mapActions(['updateTutorSubscription']),

    goToStudentDashboard() {
    this.$router.push("/tutor-dashboard");
  },

    editProfile() {
      this.profileEditMode = true;
    },

    cancelEdit() {
      this.profileEditMode = false;
    },

    async saveProfile() {

      try {
        await axios.put(`${this.$baseURL}/api/update-tutor-payouts`, {
          bank_name: this.bankName,
          bank_account: this.bankAccount,
          tutor_id: this.tutorId
        });

        this.profileEditMode = false;
        alert("Datos bancarios actualizados correctamente");
      } catch (error) {
        console.error("Error updating bank account:", error.message);
        alert("Error actualizando datos bancarios");
      }
    },
  },
};
</script>

<style>
body {
  background: #38b6ff;
}

.card {
      background-color: white;
    }

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1040;
  background-color: rgba(0, 0, 0, 0.5);
}

.modal-content {
  max-height: calc(100vh - 60px);
}

.modal-body {
  overflow-y: auto;
}
</style>

  