<template>
  <div class="container" style="width: 100vw;">
    <div class="header-and-container" style="display: flex; flex-direction: column; height: 100vh;">
      <div class="header" style="margin-top: 10px;">
        <img :src="HeaderLogo" alt="HeaderLogo" class="header-logo" />
        <router-link to="tutor-profile">
          <img :src="userImage" alt="User" class="user-image" />
        </router-link>
        
      </div>
      <div class="payment-plan-container">
      <div class="card-container">
        <div class="card">
          <h2>Suscripción Mensual</h2>
          <div><span class="price">$50.00 MXN</span><span> + IVA</span></div>
          <p>al mes</p>
          <div style="text-align: left; margin-left: 40px; margin-right: 40px;">
            <p>- Directorio de tutores</p>
            <p>- Ofrece tu servicio en nuestra plataforma</p>
            <p>- Obten estudiantes de todo Mexico</p>

          </div>
          <button class="subscribe-btn" @click="subscribe('price_1NN2V0DbP1recgpHQnWF1uO6')">Suscribirme</button>
        </div>
        <div class="card">
          <h2>Suscripción Anual</h2>
          <div class="d-flex align-items-center"><span class="original-price">$600.00 MXN + IVA</span><strong style="color:red; font-size: 22px;"> 15%</strong></div>
          <div><span class="price">$500.00 MXN</span><span> + IVA</span></div>
          <p>por 12 meses</p>
          <div style="text-align: left; margin-left: 40px; margin-right: 40px;">
            <p>- Acceso a todos los beneficios</p>
            <p>- ¡Te regalamos 2 mes!</p>
          </div>
          <p class="savings">Ahorra $100.00 MXN</p>
          <button class="subscribe-btn" @click="subscribe('price_1NN2emDbP1recgpH3h7gZlUq')">Suscribirme</button>
        </div>
      </div>
      </div>
    </div>
  </div>
</template>
  
  
  <script>
  import axios from 'axios';
  import { mapState, mapActions } from 'vuex';
  import { loadStripe } from '@stripe/stripe-js';

  import useFacebookPixel from '@/composables/useFacebookPixel';
  import { onMounted } from 'vue';


export default {
  setup() {
    const { track } = useFacebookPixel();

    onMounted(() => {
      track('ViewContent', { content_name: 'Payment Plan' });
    });

    return {};
  },
    data() {
    return {
      userImage: require('@/assets/user.png'),
      HeaderLogo: require('@/assets/Mathpaas-white.png')
    };
  },


    computed: {
    ...mapState(['tutorEmail','tutorId']),


  },

    mounted() {
  // Fetch the user data
  axios
    .get(`${this.$baseURL}/api/users/tutor-data`, {
      params: {
        email: this.tutorEmail || this.$route.query.email,
      },
    })
    .then((response) => {
      const data = response.data.data;
      this.updateTutorId(data.id);
      this.updateTutorEmail(data.email);
      axios
    .get(`${this.$baseURL}/api/get-tutor-subscriptions`, {
      params: {
        tutor_id: data.id,
      },
    })
    .then((response) => {
      const subscription_data = response.data.data[0];
      this.updateTutorSubscription(subscription_data.subscription);
    })
    .catch((error) => {
      console.error('Error fetching subscription details:', error);
    });
    })
    .catch((error) => {
      console.error("Error fetching user data:", error);
    });

    
},

    name: 'PaymentPlan',

    methods: {

        ...mapActions(['updateUserId','updateSubscription', 'updateEmail']),

    async subscribe(price) {
  const stripe = await loadStripe("pk_live_51MydE2DbP1recgpHTJ958XBbf6BNIjnWWNkLC0JOYweEdzXhXDlXNj3pBjV3bV8Or9tsv8lRbJGZGTrGRTR7UddG003HNV22iK");
  axios
    .post(`${this.$baseURL}/api/create-checkout-session`, {
      user_id: this.userId,
      email: this.email,
      price: price, // Change this property name to match the backend
    }) 
    .then((response) => {
      const sessionId = response.data.sessionId;
      stripe.redirectToCheckout({ sessionId });
    })
    .catch((error) => {
      console.error('Error creating checkout session:', error);
    });
  },
    },

}
  </script>
  
  <style scoped>
  .payment-plan-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
  }
  
  .card-container {
    display: flex;
    justify-content: space-between;
    width: 100%;
    height: 80%;
    margin-top: -2.5%;
  }
  
  .card {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: white;
    border-radius: 10px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    padding: 10px;
    width: 32%;
    height: 100%;
    text-align: center;
  }
  
  .card.annual {
    height: 110%;
    margin-top: -2.5%;
  }
  
  .price {
    font-size: 24px;
    font-weight: bold;
    margin: 10px 0;
  }
  
  .savings {
    font-size: 14px;
    color: #28a745;
    margin-top: 40px;
  }
  
  .subscribe-btn {
    background-color: #007bff;
    border: none;
    border-radius: 5px;
    color: #ffffff;
    cursor: pointer;
    font-size: 18px;
    margin-top: 20px;
    padding: 10px 20px;
  }


  .original-price {
    font-size: 18px;
    text-decoration: line-through;
    color: red;
    margin-right: 10px;
  }

  @media (max-width: 768px) {
    .card-container {
      flex-direction: column;
      align-items: center;
    }

    .card {
      width: 90%;
      margin-bottom: 20px;
    }

    .card.annual {
      margin-top: 20px;
    }
  }

  </style>
  