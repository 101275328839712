<template>
  <div class="container">
    <div class="row">
      <div class="col-12">
        <div class="header" style="margin-top: 10px;">
          <img :src="HeaderLogo" alt="HeaderLogo" class="header-logo" />
          <router-link to="tutor-profile">
            <img :src="userImage" alt="User" class="user-image" />
          </router-link>
        </div>
        <div class="greeting-container">
            <h1 class="greeting mb-4">¡Hola, {{ tutorName }}!</h1>
        </div>
        <div v-if="isDataLoaded && users.length!=0">
          <div class="card mb-4" v-if="tutorType==0">
              <div class="card-content" v-if="tutorBank==null">
                <div class="stats-container" style="width:100%">
                  <div class="stat-column" style="width:100%">
                    <p class="stat-title">Ingresa tus datos bancarios para empezar a recibir comisiones</p>
                    <button class="btn btn-primary" type="button" @click="toEditPayouts()">Ingresar datos bancarios</button>
                  </div>
                </div>
              </div>
              <div class="card-content" v-if="!isMobile()&&tutorBank!=null">
                <div class="stats-container" style="width:100%">
                  <div class="stat-column" style="width:33%">
                    <p class="stat-number">{{ active_users }}</p>
                    <p class="stat-title">Alumnos suscritos</p>
                  </div>
                  <div class="stat-column" style="width:33%">
                    <p class="stat-number">${{ cash_amount }}</p>
                    <p class="stat-title">Comisión actual</p>
                  </div>
                  <div class="stat-column" style="width:34%">
                    <p class="stat-date"><br><br>{{ payout_date }}</p>
                    <p class="stat-title">Fecha de corte</p>
                    <button class="btn btn-primary" type="button" @click="toEditPayouts()">Actualizar datos bancarios</button>
                  </div>
                </div>
              </div>
              <div class="card-content" v-if="isMobile()&&tutorBank!=null">
                <div class="stats-container-mobile" style="width: 100%">
                  <div class="stat-column">
                    <p class="stat-number">{{ active_users }}</p>
                    <p class="stat-title">Alumnos suscritos</p>
                  </div>
                  <div class="stat-column">
                    <p class="stat-number">${{ cash_amount }}</p>
                    <p class="stat-title">Comisión actual</p>
                  </div>
                  <div class="stat-column stat-column-date">
                    <p class="stat-date">{{ payout_date }}</p>
                    <p class="stat-title">Fecha de corte</p>
                  </div>
                  <div class="stat-column stat-column-date">
                    <button class="btn btn-primary" type="button" @click="toEditPayouts()">Actualizar datos bancarios</button>
                  </div>
                </div>
              </div>
          </div>
          
          
          
        <div
          class="card score-report-card mb-4"
          v-for="(user, index) in users"
          :key="index"
        >
          <div v-if="user.scoreReport!=null">
            <div class="card-content" v-if="!isMobile()">
              <div class="text-and-button-container">
                <div class="remaining-time-container">
                  <div class="remaining-time-item">
                    {{ user.first_name }} {{ user.last_name }}
                    <div style="font-size: 12px;">
                    {{ user.email }} <br>
                    {{ user.phone_number }} <br>
                    Ultima simulacion: <br>
                    {{ this.formatDate(user.scoreReport.Created_at) }}
                    </div>
                  </div>
                </div>
                <div class="view-exam-container">
                  <button class="btn btn-primary" type="button" @click="openWhatsapp(user.phone_number)">Contactar</button>
                </div>
              </div>
              <!-- <h3 class="card-title">Informe #{{ index + 1 }}</h3> -->
          <div class="progress-container" style="margin-right: 10px; margin-left: 10px;">
            <div
              class="progress-item"
              v-for="(score, label) in limitedScoreReport(user.scoreReport)"
              :key="label"
            >
              <RadialProgress
                :key="label"
                :totalSteps="stepsLabel[label]"
                :completedSteps="score"
                :startColor="colorByScore(100*score/stepsLabel[label])"
                :stopColor="colorByScore(100*score/stepsLabel[label])"
                :showProgress="true"
                :text="`${label}: ${score}`"
                :diameter="120"
                :strokeWidth="7"
                :innerStrokeWidth="7"
                :innerStrokeColor="'#ECECEC'"
              >
                <h4>{{ score }}</h4>
                <p>{{formatLabel(label)}}</p>              
              </RadialProgress>
            </div>
          </div>
          <div class="text-and-button-container">
        <div class="remaining-time-container">
          <div class="remaining-time-item">
            <strong>Sección 1</strong> <br> Completado: {{ formatTime(user.scoreReport.Time_1) }} min
          </div>
          <div class="remaining-time-item">
            <strong>Sección 2</strong> <br> Completado: {{ formatTime(user.scoreReport.Time_2) }} min
          </div>
        </div>
        <div class="view-exam-container">
          <button class="btn btn-primary" @click="viewHistory(user.email)">
            Ver historial
          </button>
        </div>
      </div>
    </div>
    <div class="card-content justify-content-between" v-if="isMobile()" style="flex-wrap: wrap;">
      <div class="row">
          <div class="col" style="width: 100%;">
            <p style="text-align: left; margin-left: 20px;">
              {{user.first_name}} {{user.last_name}}<br>
              {{ user.email }}<br>
              {{ user.phone_number }}
            </p>
          </div>
        </div>
        <div class="progress-item" style="width: 100%; max-width: 100%;">        
        <div class="row">
          <div class="col" style="width: 50%;">
            <p style="text-align: left; margin-left: 20px;">Fecha</p>
          </div>
          <div class="col" style="width: 50%; margin-right: 20px;">
            <p style="text-align: left; margin-left: 20px;">{{ formatDate(user.scoreReport.Created_at) }}</p>
          </div>
          </div>
        </div>
        <div class="view-exam-container justify-content-center">
<button class="btn btn-primary" style="width: 60%; margin-bottom:20px" @click="viewHistory(user.email)">
Contactar
</button>
</div>
      <div class="progress-item" style="width: 100%; max-width: 100%;" v-for="(score, label) in limitedScoreReport(user.scoreReport)" :key="label">        
        <div class="row">
          <div class="col" style="width: 50%;">
            <p style="text-align: left; margin-left: 20px;">{{formatLabel(label)}}</p>
          </div>
          <div class="col" style="width: 50%; margin-right: 20px;">
            <div class="progress" style="width: 100%; height: 10px; border-radius: 50px;">
    <div class="progress-bar" role="progressbar" :style="{ width: `${(score/stepsLabel[label])*100}%`, 'background-color': colorByScore(100*score/stepsLabel[label]), height: '10px', 'border-radius': '50px' }" :aria-valuenow="score" aria-valuemin="0" :aria-valuemax="stepsLabel[label]"></div>
</div>

        <p>{{score}} / {{stepsLabel[label]}}</p>

          </div>
        </div>
        </div>
        <div class="progress-item" style="width: 100%; max-width: 100%;">
          <div class="row">
              <div class="col" style="width: 50%; text-align: left; margin-left: 20px;">
                <strong>Prueba 1</strong>
              </div>
              <div class="col" style="width:50%; text-align: left;">
                <p>{{ formatTime(user.scoreReport.Time_1) }} minutos</p>
              </div>
            </div>
        </div>
        <div class="progress-item" style="width: 100%; max-width: 100%;">
          <div class="row">
              <div class="col" style="width: 50%; text-align: left; margin-left: 20px;">
                <strong>Prueba 2</strong>
              </div>
              <div class="col" style="width: 50%; text-align: left;">
                <p>{{ formatTime(user.scoreReport.Time_2) }} minutos</p>
              </div>
            </div>
        </div>
<div class="view-exam-container justify-content-center">
<button class="btn btn-primary" style="width: 60%;" @click="viewHistory(user.email)">
Ver historial
</button>
</div>
</div>
</div>
<div class="card-body" v-if="user.scoreReport==null">
  <div class="text-and-button-container" v-if="!isMobile()">
                <div class="remaining-time-container">
                  <div class="remaining-time-item">
                    {{ user.first_name }} {{ user.last_name }}
                    <div style="font-size: 12px;">
                    {{ user.email }} <br>
                    {{ user.phone_number }} <br>
                    </div>
                  </div>
                </div>
                <div class="view-exam-container">
                  <button class="btn btn-primary" @click="viewHistory(user.email)">
                    Contactar
                  </button>
                </div>
              </div>
        <div class="row" v-if="isMobile()">
          <div class="col" style="width: 100%;">
            <p style="text-align: left; margin-left: 20px;">
              {{user.first_name}} {{user.last_name}}<br>
              {{ user.email }}<br>
              {{ user.phone_number }}
            </p>
          </div>
          <div class="view-exam-container justify-content-center">
<button class="btn btn-primary" style="width: 60%; margin-bottom: 20px;" @click="viewHistory(user.email)">
Contactar
</button>
</div>
        </div>
              <div style="align-items: center;justify-content: center;">
                  Este alumno aun no ha realizado una simulacion del examen

              </div>
</div>  
</div>
</div>
      </div>
    </div>
  </div>
</template>
  
  <script>
  import axios from 'axios';
  import RadialProgress from "vue3-radial-progress";
  import { mapState, mapActions } from 'vuex';

  

  export default {
    components: {
    RadialProgress,
},

computed: {
  ...mapState(['tutorEmail', 'tutorId', 'isSubscribedTutor']),




  },

    data() {
      return {
        tutorName: '', // Replace this with actual user's name
        tutorCode: '',
        tutorType: null,
        tutorDate: '',
        tutorBank: '',
        tutorAccount: '',
        payout_date:'',
        active_users: 0,
        cash_amount: 0,
        users: [],
        scoreReport: {
      Total: 0,
      Prueba1: 0,
      Prueba2: 0,
      Aritmetica: 0,
      Algebra: 0,
      Probabilidad: 0,
      Geometria: 0,
      Observar: 0,
      Time_1: 0,
      Time_2: 0,
    },
    stepsLabel:{
        Total: 800,
        Prueba1: 400,
        Prueba2: 400,
        Aritmetica: 100,
        Algebra: 100,
        Probabilidad: 100,
        Geometria: 100,
        Observar: 100
      },
      isDataLoaded: false,
      userImage: require('@/assets/user.png'),
      HeaderLogo: require('@/assets/Mathpaas-white.png')
      };
    },
    mounted() {
  // Fetch the user data
  axios
    .get(`${this.$baseURL}/api/users/tutor-data`, {
      params: {
        email: this.tutorEmail || this.$route.query.email,
      },
    })
    .then((response) => {
      const data = response.data.data;
      this.tutorName = data.first_name;
      this.updateTutorId(data.id);
      this.tutorCode = data.referral_code;
      this.updateTutorEmail(data.email);
      this.tutorType = data.is_school;
      this.tutorDate = data.created_at
      this.tutorBank = data.bank_name;
      this.tutorAccount = data.bank_account;
      this.payoutDate();
      
      axios
    .get(`${this.$baseURL}/api/get-tutor-subscriptions`, {
      params: {
        tutor_id: data.id,
      },
    })
    .then((response) => {
      const subscription_data = response.data.data[0];
      this.updateTutorSubscription(subscription_data.subscription);
      axios
      .get(`${this.$baseURL}/api/users-by-tutor-code`, {
      params: {
        tutor_code: this.tutorCode,
      },
    })
    .then((response) => {
      this.users = response.data.data;
      this.users.forEach(user => {
      
      axios
    .get(`${this.$baseURL}/api/users/user-data`, {
      params: {
        email: user.email,
      },
    })
    .then((response) => {
      const data = response.data.data;
      if(data.scoreReport!=null){
        user.scoreReport = data.scoreReport;
        user.subscription =data.subscription;
      } else {
        user.scoreReport = null;
      }
      if(data.subscription!=null){
        user.subscription =data.subscription.Subscription;
        user.next_charge_date = data.subscription.Next_Charge_Date;
        const currentDate = new Date();
const currentMonth = currentDate.getMonth(); // 0-based, so January is 0
const currentYear = currentDate.getFullYear();

const nextChargeDate = new Date(user.next_charge_date);
const nextChargeMonth = nextChargeDate.getMonth();
const nextChargeYear = nextChargeDate.getFullYear();

if (
  user.subscription == 1 &&
  (
    (nextChargeYear === currentYear && nextChargeMonth === currentMonth) ||
    (nextChargeYear === currentYear && nextChargeMonth === currentMonth + 1) ||
    (currentMonth === 11 && nextChargeYear === currentYear + 1 && nextChargeMonth === 0) // December to January transition
  )
) {
  this.active_users = this.active_users + 1;
  this.cash_amount = this.cash_amount + 100;
}

      } else {
        user.subscription = null;
      }
    })
    .catch((error) => {
      console.error('Error fetching users data:', error);
    });
    })
      this.cashAmount();
    this.isDataLoaded = true;
  })
    .catch((error) => {
      console.error('Error fetching users data:', error);
    });
    })
    .catch((error) => {
      console.error('Error fetching subscription details:', error);
    });
    })
    .catch((error) => {
      console.error("Error fetching tutor data:", error);
    });

    
},

    methods: {

      toEditPayouts(){
        this.$router.push('/tutor-payouts');
      },

      activeUsers() {
    this.active_users = this.users.filter(user => user.subscription == 1 );

  },
  cashAmount() {
    this.cash_amount = this.active_users * 100;
  },

  payoutDate() {
  // Split the date and time parts
  const datePart = this.tutorDate.split('T')[0];
  // Split the date part into year, month, and day
  const [year, month] = datePart.split('-');
  
  // Get the last day of the given month
  const lastDayOfMonth = new Date(year, month, 0).getDate(); // Using 0 to get the last day of the previous month

  const monthName = new Date(year, month - 1).toLocaleString('es-ES', { month: 'long' }); // Month in Spanish
  const currentYear = new Date().getFullYear();

  this.payout_date = `${lastDayOfMonth} de ${monthName}, ${currentYear}`;
},


      openWhatsapp(phone) {
        let digitsOnly = phone;

    // Check if phone starts with a '+', remove it
    if (phone.startsWith('+')) {
        digitsOnly = phone.substring(1);
    }

    // Check if phone has 10 digits, prefix with '52'
    if (digitsOnly.length === 10) {
            digitsOnly = '52' + digitsOnly;
        }

    // Construct the URL with the processed phone number
    let url = `https://api.whatsapp.com/send?phone=${digitsOnly}`;

    // Open the URL
    window.open(url, '_blank');
},

      formatDate(dateString) {
        const options = { year: 'numeric', month: 'numeric', day: 'numeric' };
        return new Date(dateString).toLocaleDateString('es-ES', options);
    },

      limitedScoreReport(scoreReport) {
        if(scoreReport!=null){
          const {
        Total,
        Prueba1,
        Prueba2,
        Aritmetica,
        Algebra,
        Probabilidad,
        Geometria,
        //observations,
      } = scoreReport;
      return {
        Total: Total,
        Prueba1: Prueba1,
        Prueba2: Prueba2,
        Aritmetica: Aritmetica,
        Algebra: Algebra,
        Probabilidad: Probabilidad,
        Geometria: Geometria,
        //Observar: observations,
      };
        }
      
    },

    formatTime(seconds) {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes.toString().padStart(2, '0')}:${remainingSeconds.toString().padStart(2, '0')}`;
  },

  viewHistory(email) {
  this.$router.push({
    name: "scoreReportHistory",
    query: { email: email } ,
  });
 },

      isMobile() {
    return window.innerWidth <= 768; // Adjust this value based on your needs
  },

      formatLabel(label) {
        const labelMap = {
          'Prueba1': 'Prueba 1',
          'Prueba2': 'Prueba 2',
          'Aritmetica': 'Aritmética',
          'Algebra': 'Álgebra',
          'Probabilidad': 'Probabilidad',
          'Geometria': 'Geometría',
          'Observar': 'Observaciones'
        };
        return labelMap[label] || label;
      },

      ...mapActions(['updateTutorId','updateTutorSubscription', 'updateTutorEmail']),


goToPaymentPlans() {
    // Navigate to the Score Report History view
    this.$router.push("/tutor-payment-plan");
  },

      goToMetrics() {
    // Navigate to the Score Report History view
    this.$router.push("/user-metrics");
  },

      goToScoreReportHistory() {
    // Navigate to the Score Report History view
    this.$router.push("/scoreReportHistory");
  },

      colorByScore(score) {
  const numericScore = Number(score);

  if (numericScore < 40) {
    return "#cc3232"; // red
  } else if (numericScore < 80) {
    return "#e7b416"; // yellow
  } else {
    return "#2dc937"; // green
  }
},
    },
  };
  </script>


  
  <style>

html, body {
  margin: 0;
  padding: 0;
}
  
  * {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.container {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
  }

  .greeting-container {
    flex-grow: 1;
    display: flex;
    justify-content: center !important;
    text-align: center !important;
  }

  .card {
      background-color: white;
    }

  .greeting {
    color: white;
    margin: 0;
  }

  .header-logo {
    height: 50px; /* Adjust the size as needed */
    width: auto;
  }

  .user-image {
    width: 50px; /* Adjust the size as needed */
    height: auto;
  }

  body {
    background: #38b6ff;
  }

  .greeting-card,
  .practice-card,
  .mock-test-card {
    margin-bottom: 20px;
  }

  .greeting-card {
    background-color: rgba(255, 255, 255, 0.8);
  }

  .practice-card,
  .mock-test-card {
    background-color: rgba(255, 255, 255, 0.9);
  }

  .progress-container {
    display: flex;
    justify-content: space-around;
    align-items: flex-start;
    flex: 1;
    flex-wrap: wrap;
    max-height: 100%;
    z-index: 1;
  }

  .stats-container {
    display: flex;
    justify-content: center; /* Center all columns horizontally */
    align-items: center; /* Center all columns vertically */
    text-align: center;
    flex-wrap: nowrap; /* Prevent wrapping of columns */
  }
  
  .stat-column {
    flex: 1;
    padding: 10px;
    display: flex;
    flex-direction: column;
    align-items: center; /* Center content within each column */
  }
  
  .stat-number {
    font-size: 3em;
    font-weight: bold;
    margin: 0;
    text-align: center; /* Center text */
  }

  .stat-date {
    font-size: 1.5em;
    font-weight: bold;
    margin: 0;
    text-align: center; /* Center text */
  }
  
  .stat-title {
    font-size: 1em;
    color: #555;
    margin-top: 5px;
    text-align: center; /* Center text */
  }
  
  .card-content {
    display: flex;
    justify-content: center; /* Center the entire card content horizontally */
    align-items: center; /* Center the entire card content vertically */
  }
  
  .stats-container-mobile {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .stats-container-mobile .stat-column {
    width: 100%;
    text-align: center;
  }
  
  .stat-column-date {
    margin-top: 20px; /* Adds space between the date column and others */
  }
  
  

  .progress-item {
    width: calc(85% / 8); /* Adjust this value based on the total width percentage you want */
    max-width: 80%; /* Adjust this value based on your preferred max-width */
  }

  .text-and-button-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    flex: 1;
    max-width: 15%; /* Adjust this value based on the total width percentage you want */
    margin-right: 0; /* Remove the margin-right */
  }

  .remaining-time-container {
    display: flex;
    justify-content: flex-end;
    flex-direction: column;
    width: 100%;
  }

  .remaining-time-item {
    text-align: left;
    margin-bottom: 0.5rem;
    margin-left: 10px;
  }

  .view-exam-container {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    margin-left: 10px;
  }

  .card-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .view-exam-container button {
    width: 85%;
  }
</style>
