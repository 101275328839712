// useFacebookPixel.js
export default function useFacebookPixel() {
    const fbq = window.fbq;
  
    function track(eventName, params) {
      if (fbq) {
        fbq('track', eventName, params);
      } else {
        console.error('Facebook Pixel not initialized');
      }
    }
  
    return { track };
  }