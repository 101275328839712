<template>
    <div>
      <template v-for="(part, index) in parsedText" :key="index">
      <div v-if="part.type === 'text'" style="display: inline;">{{ part.content }}</div>
      <div v-else v-katex="part.content" style="display: inline;"></div>
      </template>
    </div>
  </template>
  
  <script>
  export default {
    props: {
      content: {
        type: String,
        default: "",
      },
    },
    computed: {
      parsedText() {
        const regex = /\$(.*?)\$/g;
        const parts = [];
        let lastIndex = 0;
        let match;
  
        while ((match = regex.exec(this.content)) !== null) {
          if (match.index > lastIndex) {
            parts.push({
              type: "text",
              content: this.content.slice(lastIndex, match.index),
            });
          }
  
          parts.push({
            type: "math",
            content: match[1],
          });
  
          lastIndex = match.index + match[0].length;
        }
  
        if (lastIndex < this.content.length) {
          parts.push({
            type: "text",
            content: this.content.slice(lastIndex),
          });
        }
  
        return parts;
      },
    },
  };
  </script>
  