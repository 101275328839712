<template>
  <div class="container d-flex align-items-center" style="height: 100vh; width: 100vw;">
      <div class="header">
          <img :src="HeaderLogo" alt="CreateLogo" class="create-logo" style="height:50px; margin-top:20px"/>
          </div>
      <div class="card-container" style="width: 800px; max-width: 95%; margin-top: 20px;">
        <div class="card">
          <div class="card-body">
        <h2 class="card-title text-center mb-4">Crear perfil</h2>
        <form @submit.prevent="createProfile">
          <div class="row">
            <div class="col-md-6 mb-3" style="text-align: left;" >
                <label style="text-align: left;" for="images">Foto de Perfil (opcional)</label>
                <input type="file" id="images" name="images" accept="image/*" multiple ref="imagesRef">
            </div>
            <div class="col-md-6 mb-3" style="text-align: left;">
                <label for="pdfs">Curriculum (opcional)</label>
                <input type="file" id="pdfs" name="pdfs" accept="application/pdf" multiple ref="pdfsRef">
            </div>
            <div class="col-md-6 mb-3">
              <input type="text" class="form-control" id="firstName" v-model="firstName" placeholder="Nombre" required>
            </div>
            <div class="col-md-6 mb-3">
              <input type="text" class="form-control" id="lastName" v-model="lastName" placeholder="Apellido" required>
            </div>
          </div>
          <div class="mb-3">
            <input type="email" class="form-control" id="email" v-model="email" placeholder="Correo electrónico" required>
          </div>
          <div class="mb-3">
            <input type="text" class="form-control" id="phoneNumber" v-model="phoneNumber" placeholder="Teléfono">
          </div>
          <div class="mb-3">
            <input type="text" class="form-control" id="country" v-model="country" placeholder="País">
          </div>
          <div class="mb-3">
            <input type="text" class="form-control" id="state" v-model="state" placeholder="Estado">
          </div>
          <div class="mb-3">
            <input type="text" class="form-control" id="city" v-model="city" placeholder="Ciudad">
          </div>
          <div class="mb-3">
            <input type="text" class="form-control" id="district" v-model="district" placeholder="Colonia">
          </div>
          <div class="mb-3">
            <input type="text" class="form-control" id="street" v-model="street" placeholder="Calle">
          </div>
          <div class="mb-3">
            <input type="text" class="form-control" id="number" v-model="number" placeholder="Numero">
          </div>
          <div class="mb-3">
            <input type="text" class="form-control" id="zip" v-model="zip" placeholder="Codigo Postal">
          </div>
          <div class="mb-3 form-check">
            <input class="form-check-input" type="radio" id="TermsYes" value="Yes" v-model="terms" style="margin-top: 20px;">
            <label style="margin-top: 17px" class="form-check-label" for="TermsYes">
                Acepto los 
                <a href="https://www.mathpaas.com/terms-conditions" target="_blank">términos y condiciones</a>
            </label>
          </div>
          <button type="submit" class="btn btn-primary">Crear perfil</button>
        </form>
      </div>
    </div>
  </div>
</div>
      
</template>
  
  <script>
  import axios from 'axios';
  import AWS from 'aws-sdk';
  import { v4 as uuidv4 } from 'uuid';
  import useFacebookPixel from '@/composables/useFacebookPixel';
  import { onMounted } from 'vue';

  AWS.config.update({
  region: 'us-east-1', // your aws region
  accessKeyId: 'AKIA6D4E4H7RYJDXF6FB', // your access key
  secretAccessKey: 'k2PShzPBXFkB9+cyqwjyqm2tLoleMkAxf8Rx+YB1' // your secret key
});

const s3 = new AWS.S3();


export default {
  setup() {
    const { track } = useFacebookPixel();

    onMounted(() => {
      track('CompleteRegistration', { content_name: 'Create Tutor' });
    });

    return {};
  },    data() {
      return {
        firstName: '',
        lastName: '',
        email: '', // You should fetch the authenticated user's email and populate this field
        phoneNumber: '',
        country: '',
        state: '',
        city: '',
        district: '',
        street: '',
        number: '',
        zip: '',
        userId: '',
        HeaderLogo: require('@/assets/Mathpaas-white.png'),
        terms:''
      };
    },
    methods: {
      generateReferralCode() {
      let datePart = Date.now().toString(36);  // unique part based on current time
      let randomPart = Math.random().toString(36).substr(2, 5); // 5 random characters
      return datePart + randomPart;
    },

      uploadToS3(file, folder) {
  const fileExtension = file.name.split('.').pop();
  const newFileName = `${uuidv4()}.${fileExtension}`; // creates a new unique file name
  const fileName = `${folder}${newFileName}`;
  const params = {
    Bucket: 'mathpaas-documents',
    Key: fileName,
    Body: file,
    ACL: 'public-read' // if you want the file to be publicly accessible
  };

  s3.upload(params, (err) => { // changed this line to an arrow function
    if (err) {
      console.error('Error uploading to S3:', err);
      return;
    } else{
      this.uploadToDB(newFileName, fileExtension, folder);
    }
});
},

async uploadToDB(fileName, fileExtension, folder){
  let fileType = 0;
      if(folder == 'profile-images/'){
        fileType = 0;
      } else {
        fileType = 1;
      }
      try {
        const files_data = {
          tutor_id: this.tutorId,
          file_name: fileName,
          file_extension: fileExtension,
          file_type: fileType,
        }


await axios.post(`${this.$baseURL}/api/tutor-files`, files_data);

} catch (error) {
console.error('Error creating tutor:', error);
}
},
      async createProfile() {
        if(this.terms=='Yes'){
          try {
          const userProfile = {
            first_name: this.firstName,
            last_name: this.lastName,
            email: this.email,
            phone_number: this.phoneNumber,
            country: this.country,
            state: this.state,
            city: this.city,
            district: this.district,
            street: this.street,
            number: this.number,
            zip: this.zip,
            referral_code: this.generateReferralCode()  // use the new function here

          };
  
          await axios.post(`${this.$baseURL}/api/create-tutor`, userProfile);
          await axios.get(`${this.$baseURL}/api/users/tutor-data`, {
      params: {
        email: this.email,
      },
    })
    .then((response) => {
      const data = response.data.data;
      console.log(data);
      this.tutorId = data.id;
       // Now that we have an assessmentId, we can upload the files
       const images = this.$refs.imagesRef.files;
        for(let i=0; i<images.length; i++) {
            this.uploadToS3(images[i], 'profile-images/');
        }
        
        const pdfs = this.$refs.pdfsRef.files;
        for(let i=0; i<pdfs.length; i++) {
            this.uploadToS3(pdfs[i], 'cvs/');
        }
    })
    .catch((error) => {
      console.error("Error fetching tutor data:", error);
    });

    const userSubs = {
            tutor_id: this.tutorId,
            subscription: 0,
            stripe_customer_id: null,
            next_charge_date: null,
          };
          await axios.post(`${this.$baseURL}/api/tutor-subscriptions`, userSubs);
          this.$router.push('/tutor-dashboard');
        } catch (error) {
          console.error('Error creating tutor profile:', error);
        }
        }
        
      },
    },
  };
  </script>
  
  <style>
  body {
    background: #38b6ff;
  }

  .card {
      background-color: white;
    }

  
  </style>