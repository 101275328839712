<template>
  <div class="container" style="height: 100vh;">
    <div class="header" style="margin-top: 10px;">
          <img :src="HeaderLogo" alt="HeaderLogo" class="header-logo" />
          <router-link v-if="this.userId!=''" to="user-profile">
            <img :src="userImage" alt="User" class="user-image" />
          </router-link>
          <router-link v-else to="tutor-profile">
            <img :src="userImage" alt="User" class="user-image" />
          </router-link>
          </div>
      <div class="card-container d-flex justify-content-center align-items-center">
    <div class="card" style="width: 500px;">
      <div class="card-body">
        <h2 class="card-title text-center mb-4">Cambiar contraseña</h2>
        <form @submit.prevent="changePassword">
          <div class="form-group">
            <label for="currentPassword">Contraseña actual</label>
            <div class="input-group">
              <input :type="showCurrentPassword ? 'text' : 'password'" class="form-control" id="currentPassword" v-model="currentPassword" required>
              <button class="btn btn-outline-secondary" type="button" @click="showCurrentPassword = !showCurrentPassword">{{ showCurrentPassword ? 'Ocultar' : 'Mostrar' }}</button>
            </div>
          </div>
          <div class="form-group">
            <label for="newPassword">Nueva contraseña</label>
            <div class="input-group">
              <input :type="showNewPassword ? 'text' : 'password'" class="form-control" id="newPassword" v-model="newPassword" required>
              <button class="btn btn-outline-secondary" type="button" @click="showNewPassword = !showNewPassword">{{ showNewPassword ? 'Ocultar' : 'Mostrar' }}</button>
            </div>
          </div>
          <div class="form-group">
            <label for="confirmPassword">Confirmar nueva contraseña</label>
            <div class="input-group">
              <input :type="showConfirmPassword ? 'text' : 'password'" class="form-control" id="confirmPassword" v-model="confirmPassword" required :class="passwordsMatch ? 'is-valid' : 'is-invalid'">
              <button class="btn btn-outline-secondary" type="button" @click="showConfirmPassword = !showConfirmPassword">{{ showConfirmPassword ? 'Ocultar' : 'Mostrar' }}</button>
            </div>
          </div>
          <div class="text-center" style="margin-top:20px;">
            <button type="submit" class="btn btn-primary">Cambiar contraseña</button>
          </div>
        </form>
      </div>
    </div>
  </div>
  </div>
</template>
  
  <script>
import { getAuth, updatePassword, EmailAuthProvider, reauthenticateWithCredential } from "firebase/auth";
import {mapState} from 'vuex';
  
  export default {

    data() {
      return {
        currentPassword: "",
        newPassword: "",
        confirmPassword: "",
        showCurrentPassword: false,
        showNewPassword: false,
        showConfirmPassword: false,
        userImage: require('@/assets/user.png'),
        HeaderLogo: require('@/assets/Mathpaas-white.png')
      };
    },
    computed: {
      ...mapState(["tutorEmail", "isSubscribedTutor", "tutorId", "userId"]),

      passwordsMatch() {
        return this.newPassword === this.confirmPassword;
      },
    },
    methods: {
        async changePassword() {
  if (this.newPassword !== this.confirmPassword) {
    alert("New password and confirm password do not match.");
    return;
  }

  try {
    const auth = getAuth();
    const user = auth.currentUser;
    const credential = EmailAuthProvider.credential(user.email, this.currentPassword);

    await reauthenticateWithCredential(user, credential);
    await updatePassword(user, this.newPassword);
    alert("Password changed successfully.");
    if(this.userId!=''){
      this.$router.push("/student-dashboard"); // Redirect to home or another page after successful password change
    } else {
      this.$router.push("/tutor-dashboard");
    }
  } catch (error) {
    console.error("Error changing password:", error.message);
    alert(error.message);
  }
},

    },
  }
  </script>
  
  <style>
  body {
    background: #38b6ff;
  }
  
  .is-valid {
    border-color: #28a745;
    background-color: rgba(40, 167, 69, 0.1);
  }
  
  .is-invalid {
    border-color: #dc3545;
    background-color: rgba(220, 53, 69, 0.1);
  }

  .card {
      background-color: white;
    }
  </style>
