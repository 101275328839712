import { createStore } from 'vuex';
import axios from 'axios';

export default createStore({
  state: {
    email: '',
    isSubscribed: 0,
    userId: '',
    remainingCredits: {
      section1: 0,
      section2: 0,
    },
    tutorEmail: '',
    isSubscribedTutor: 0,
    tutorId: '',
  },
  mutations: {
    setEmail(state, email) {
      state.email = email;
    },
    setUserId(state, userId) {
      state.userId = userId;
    },
    setSubscriptionStatus(state, subscriptionStatus) {
      state.isSubscribed = subscriptionStatus;
    },
    setTutorEmail(state, tutorEmail) {
      state.tutorEmail = tutorEmail;
    },
    setTutorId(state, tutorId) {
      state.tutorId = tutorId;
    },
    setTutorSubscriptionStatus(state, subscriptionStatus) {
      state.isSubscribedTutor = subscriptionStatus;
    },
    setRemainingCredits(state, { section, credits }) {
      if (section == 1) {
        state.remainingCredits.section1 = credits;
      }
      if (section == 2) {
        state.remainingCredits.section2 = credits;
      }
    },
  },
  actions: {
    updateEmail({ commit }, email) {
      commit('setEmail', email);
    },
    updateUserId({ commit }, userId) {
      commit('setUserId', userId);
    },
    updateSubscription({ commit }, subscriptionStatus) {
      commit('setSubscriptionStatus', subscriptionStatus);
    },
    updateTutorEmail({ commit }, tutorEmail) {
      commit('setTutorEmail', tutorEmail);
    },
    updateTutorId({ commit }, tutorId) {
      commit('setTutorId', tutorId);
    },
    updateTutorSubscription({ commit }, subscriptionStatus) {
      commit('setTutorSubscriptionStatus', subscriptionStatus);
    },
    updateRemainingCredits({ commit }, payload) {
      commit('setRemainingCredits', payload);
    },
    updateSubscriptionStatus({ commit, state }) {
      axios
        .get(`${this.$baseURL}/api/get-user-subscriptions`, {
          params: {
            user_id: state.userId,
          },
        })
        .then((response) => {
          const subscriptionData = response.data.data[0];
          commit('setSubscriptionStatus', subscriptionData.subscription);
        })
        .catch((error) => {
          console.error('Error fetching subscription details:', error);
        });
    },
    updateTutorSubscriptionStatus({ commit, state }) {
      axios
        .get(`${this.$baseURL}/api/get-tutor-subscriptions`, {
          params: {
            tutor_id: state.tutorId,
          },
        })
        .then((response) => {
          const subscriptionData = response.data.data[0];
          commit('setTutorSubscriptionStatus', subscriptionData.subscription);
        })
        .catch((error) => {
          console.error('Error fetching subscription details:', error);
        });
    },
  },
  getters: {
    getEmail: (state) => state.email,
    getUserId: (state) => state.userId,
    getSubscriptionStatus: (state) => state.isSubscribed,
    getRemainingCredits: (state) => state.remainingCredits,
    getTutorEmail: (state) => state.tutorEmail,
    getTutorUserId: (state) => state.tutorId,
    getTutorSubscriptionStatus: (state) => state.isSubscribedTutor,
  },
});
