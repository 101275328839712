<template>
  <div class="container d-flex align-items-center" style="height: 100vh; width:100vw;">
    <img :src="Logo" alt="CreateLogo" class="create-logo" style="height: 50px; margin-top: 20px;"/>
    <div class="card-container" style="max-width: 400px; margin-top: 20px; height: 500px;">
    <div class="card">
      <div class="card-body d-flex flex-column justify-content-between">
        <div>
          <h2 class="card-title text-center mb-4">Crear cuenta</h2>
        <form @submit.prevent="signUp">
          <div class="mb-3">
            <input type="email" class="form-control" id="email" v-model="email" placeholder="Correo electrónico">
          </div>
          <div class="mb-3">
      <div class="input-group">
        <input :type="showPassword ? 'text' : 'password'" class="form-control" id="password" v-model="password" placeholder="Contraseña">
        <button class="btn btn-outline-secondary" type="button" @click="showPassword = !showPassword">{{ showPassword ? 'Ocultar' : 'Mostrar' }}</button>
      </div>
    </div>
    <div class="mb-3">
      <div class="input-group">
        <input :type="showConfirmPassword ? 'text' : 'password'" class="form-control" id="confirmPassword" v-model="confirmPassword" placeholder="Confirmar contraseña" :class="passwordsMatch ? 'is-valid' : 'is-invalid'">
        <button class="btn btn-outline-secondary" type="button" @click="showConfirmPassword = !showConfirmPassword">{{ showConfirmPassword ? 'Ocultar' : 'Mostrar' }}</button>
      </div>
    </div>
          <button type="submit" class="btn btn-primary" @click="signUp">Crear cuenta</button>
        </form>
        </div>
<!--         <div>
          <hr class="mb-4" />
        <div class="d-grid gap-2">
          <button class="btn btn-danger" @click="signInWithGoogle">Crear cuenta con Google</button>
          <button class="btn btn-primary mb-4" @click="signInWithFacebook">Crear cuenta con Facebook</button>
        </div>
        </div> -->
      </div>
      <div class="card-footer text-center">
        <p class="mb-0">¿Ya tienes una cuenta? <router-link to="/tutor-signin">Iniciar sesión</router-link></p>
      </div>
    </div>
  </div>
  <div class="card-container" style="max-width: 400px; height: 200px;">
      <div class="card">
        <div class="card-body d-flex flex-column justify-content-center">
          <h2 class="card-title text-center mb-4">¿Eres alumno?</h2>
          <div class="d-grid gap-2">
            <router-link to="/signup" class="btn btn-primary">Soy alumno</router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

  
  <script>
import { getAuth, createUserWithEmailAndPassword, sendEmailVerification } from 'firebase/auth';
import { mapActions } from 'vuex';

  import useFacebookPixel from '@/composables/useFacebookPixel';
  import { onMounted } from 'vue';
  import Swal from 'sweetalert2';



export default {
  setup() {
    const { track } = useFacebookPixel();

    onMounted(() => {
      track('Lead', { content_name: 'Sign up' });
    });

    return {};
  },    data() {
      return {
        email: "",
        password: "",
        confirmPassword: '',
        showPassword: false,
        showConfirmPassword: false,
        errorMessage: null, // Add this line
        Logo: require('@/assets/Mathpaas-white.png')
      };
    },
    computed: {
  passwordsMatch() {
    return this.password === this.confirmPassword;
  },
},
    methods: {
      ...mapActions(['updateTutorEmail']),

      async signUp() {
      // Check if passwords match
      if (!this.passwordsMatch) {
        Swal.fire({
          title: 'Las contraseñas no coinciden',
          text: 'Por favor, asegúrate de que ambas contraseñas coincidan.',
          icon: 'error',
          confirmButtonText: 'OK',
          confirmButtonColor: '#0d6efd'
        });
        return;
      }

      try {
        const auth = getAuth();
        const result = await createUserWithEmailAndPassword(auth, this.email, this.password);

        await sendEmailVerification(result.user);
        this.updateEmail(this.email);

        this.$router.push('/create-tutor');
      } catch (error) {
        console.log('Error during sign-up:', error);
        // Handle specific authentication errors
        if (error.code === 'auth/email-already-in-use') {
          Swal.fire({
            title: 'Correo ya registrado',
            text: 'El correo electrónico ingresado ya está registrado. ¿Deseas iniciar sesión?',
            icon: 'error',
            showCancelButton: true,
            confirmButtonColor: '#0d6efd',
            confirmButtonText: 'Iniciar sesión',
            cancelButtonText: 'Cancelar',
          }).then((result) => {
            if (result.isConfirmed) {
              // Navigate to sign-in page
              this.$router.push('/tutor-signin');
            }
          });
        } else {
          // Generic error handling
          Swal.fire({
            title: 'Error al crear cuenta',
            text: 'Ha ocurrido un error al crear la cuenta. Por favor, intenta nuevamente.',
            icon: 'error',
          });
        }
      }
    },


},
  };
  </script>

  <style>
  body {
  background: #38b6ff !important;
  }

  .card {
      background-color: white;
    }
    
  .is-valid {
  border-color: #28a745;
  background-color: rgba(40, 167, 69, 0.1);
}

.is-invalid {
  border-color: #dc3545;
  background-color: rgba(220, 53, 69, 0.1);
}
  </style>