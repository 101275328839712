<template>
  <div
    class="container d-flex align-items-center"
    style="height: 100vh; width: 100vw;"
  >
    <img
      :src="Logo"
      alt="CreateLogo"
      class="create-logo"
      style="height: 50px; margin-top: 20px;"
    />
    <div
      class="card-container"
      style="max-width: 400px; margin-top: 20px; height: 500px;"
    >
      <div class="card">
        <div
          class="card-body d-flex flex-column justify-content-between"
        >
          <div>
            <h2 class="card-title text-center mb-4">
              Iniciar sesión
            </h2>
            <form @submit.prevent="signIn">
              <div class="mb-3">
                <input
                  type="email"
                  class="form-control"
                  id="email"
                  v-model="email"
                  placeholder="Correo electrónico"
                />
              </div>
              <div class="mb-3">
                <input
                  type="password"
                  class="form-control"
                  id="password"
                  v-model="password"
                  placeholder="Contraseña"
                />
              </div>
              <button type="submit" class="btn btn-primary">
                Iniciar sesión
              </button>
            </form>
          </div>
        </div>
        <div class="card-footer text-center">
          <p class="mb-0">
            ¿Aún no tienes cuenta?<br>
            <router-link to="/signup">Crear cuenta</router-link>
          </p>
          <p class="mb-0">¿Olvidaste tu contraseña? <a href="#" @click="promptForPasswordReset"><br>Recuperar contraseña</a></p>
        </div>
      </div>
    </div>
    <div class="card-container" style="max-width: 400px; height: 200px;">
      <div class="card">
        <div class="card-body d-flex flex-column justify-content-center">
          <h2 class="card-title text-center mb-4">¿Eres tutor?</h2>
          <div class="d-grid gap-2">
            <router-link to="/tutor-signin" class="btn btn-primary"
              >Soy tutor</router-link
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getAuth, signInWithEmailAndPassword, signInWithPopup, GoogleAuthProvider, FacebookAuthProvider, sendPasswordResetEmail  } from 'firebase/auth';

import { mapActions } from 'vuex';
import axios from 'axios';
import useFacebookPixel from '@/composables/useFacebookPixel';
import { onMounted } from 'vue';
import Swal from 'sweetalert2';

export default {
  setup() {
    const { track } = useFacebookPixel();

    onMounted(() => {
      track('Lead', { content_name: 'Sign in' });
    });

    return {};
  },
  data() {
    return {
      email: '',
      password: '',
      Logo: require('@/assets/Mathpaas-white.png'),
    };
  },
  methods: {
    ...mapActions(['updateEmail']),

    async signIn() {
      try {
        // Sign in the user
        await signInWithEmailAndPassword(
          getAuth(),
          this.email,
          this.password
        );

        // Optionally update the email in the store
        this.updateEmail(this.email);

        // Navigate to the student dashboard
        this.$router.push('/student-dashboard');
      } catch (error) {
        console.error('Error signing in:', error);

        // Handle specific authentication errors
        if (error.code === 'auth/wrong-password') {
          Swal.fire({
            title: 'Contraseña incorrecta',
            text: 'La contraseña ingresada es incorrecta. ¿Deseas recuperar tu contraseña?',
            icon: 'error',
            showCancelButton: true,
            confirmButtonColor: '#0d6efd',
            confirmButtonText: 'Recuperar contraseña',
            cancelButtonText: 'Cancelar',
          }).then((result) => {
            if (result.isConfirmed) {
              // Navigate to password recovery page
              this.promptForPasswordReset()     
            }
          });
        } else if (error.code === 'auth/user-not-found') {
          Swal.fire({
            title: 'Usuario no encontrado',
            text: 'El correo electrónico ingresado no está registrado. ¿Deseas crear una cuenta?',
            icon: 'error',
            showCancelButton: true,
            confirmButtonColor: '#0d6efd',
            confirmButtonText: 'Crear cuenta',
            cancelButtonText: 'Cancelar',
          }).then((result) => {
            if (result.isConfirmed) {
              // Navigate to signup page
              this.$router.push('/signup');
            }
          });
        } else {
          // Generic error handling
          Swal.fire({
            title: 'Error al iniciar sesión',
            text: 'Ha ocurrido un error al iniciar sesión. Por favor, intenta nuevamente.',
            icon: 'error',
          });
        }
      }
    },

    async promptForPasswordReset() {
  const { value: email } = await Swal.fire({
    title: '<h3>¿Olvidaste tu contraseña?</h3>',
    html:
      '<div style="text-align: left;">' +
      '<small>Ingresa tu correo y da clic en el botón para recuperar tu contraseña.<br>' +
      '<input id="swal-input1" class="swal2-input" placeholder="Tu correo electrónico"><br><br>' +
      'Te enviaremos un correo para que puedas reestablecerla.<br>' +
      '</small>' +
      '</div>',
      denyButtonText: 'Regresar',
                    denyButtonColor: '#0d6efd',
                    confirmButtonText: 'Continuar',
                    confirmButtonColor: '#0d6efd',
                    showCloseButton: true,
                    showCancelButton: false,  // Ensuring no extra cancel button
                    showDenyButton: true,
                    focusConfirm: false,
                    didOpen: () => {
  const confirmButton = document.querySelector('.swal2-confirm');
  const denyButton = document.querySelector('.swal2-deny');
  confirmButton.parentNode.insertBefore(denyButton, confirmButton);
},
    preConfirm: () => {
      return document.getElementById('swal-input1').value;
    }
  });

  if (email) {
    try {
      const auth = getAuth();
      await sendPasswordResetEmail(auth, email);
      Swal.fire({
        title: '¡Correo de recuperación enviado!',
        text: 'Revisa tu correo electrónico y sigue las instrucciones para recuperar tu cuenta.',
        icon: 'success',
        confirmButtonText: 'Continuar',
        confirmButtonColor: '#0d6efd'
      });
    } catch (error) {
      console.error('Error sending password reset email:', error);
      Swal.fire({
        title: '<h3>Parece que ocurrió un error</h3>',
        html:
          '<div style="text-align: left;">' +
          '<small>Por favor, contáctanos por WhatsApp al +528110668259 para ayudarte a recuperar tu cuenta.' +
          '</small>' +
          '</div>',
        icon: 'error',
        confirmButtonText: 'Continuar',
        confirmButtonColor: '#0d6efd',
        showCloseButton: true,

      });
    }
  }
},

    async signInWithProvider(provider) {
      try {
        const auth = getAuth();
        const result = await signInWithPopup(auth, provider);

        const userEmail = result.user.email;
        this.updateEmail(userEmail);

        // Fetch user data
        const response = await axios.get(
          `${this.$baseURL}/api/users/user-data`,
          {
            params: {
              email: userEmail,
            },
          }
        );

        const success = response.data.success;
        if (success) {
          this.$router.push('/student-dashboard');
        } else {
          // Navigate to CreateUser if the user data fetch was not successful
          this.$router.push('/create-profile');
        }
      } catch (error) {
        console.error(
          `Error signing in with ${provider.providerId}:`,
          error
        );
        // Optionally navigate to an error page or show a notification
      }
    },

    async signInWithGoogle() {
      const provider = new GoogleAuthProvider();
      await this.signInWithProvider(provider);
    },

    async signInWithFacebook() {
      const provider = new FacebookAuthProvider();
      await this.signInWithProvider(provider);
    },
  },
};
</script>

<style>
body {
  background: #38b6ff;
}

.card {
  background-color: white;
}
</style>
