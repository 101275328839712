import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import store from './store'; // Import the store
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';
import 'bootstrap';
import katex from 'katex';
import 'katex/dist/katex.min.css';


import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';

const firebaseConfig = {
    apiKey: "AIzaSyAUBn7qv3EDd4YK75ZFNLUHYFfdIlXg8Vs",
    authDomain: "paa-math.firebaseapp.com",
    projectId: "paa-math",
    storageBucket: "paa-math.appspot.com",
    messagingSenderId: "251559707480",
    appId: "1:251559707480:web:5d7f423ed0c0921e5f9b2d",
    measurementId: "G-XJ52PJ21MX"
  };

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);

export { app, auth };

const vueApp = createApp(App);

vueApp.config.globalProperties.$baseURL = 'https://api.mathpaas.com';


vueApp.directive('katex', {
  beforeMount(el, binding) {
    try {
      const renderedMath = katex.renderToString(binding.value);
      el.innerHTML = renderedMath;
    } catch (error) {
      console.error('Error rendering KaTeX:', error);
    }
  },
  updated(el, binding) {
    try {
      const renderedMath = katex.renderToString(binding.value);
      el.innerHTML = renderedMath;
    } catch (error) {
      console.error('Error rendering KaTeX:', error);
    }
  },
});

vueApp.use(router).use(store).mount('#app');
