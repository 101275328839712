<template>
  <div class="container">
    <div class="row">
      <div class="col-12">
        <div class="header" style="margin-top: 10px;">
          <img :src="HeaderLogo" alt="HeaderLogo" class="header-logo" />
        </div>
        <div class="greeting-container">
            <h1 class="greeting mb-4">Lista de tutores</h1>
        </div>
        <div v-if="isDataLoaded && tutors.length!=0 && this.$route.params.admin_password == 'MayaLuna107'">
          <div v-for="(tutor, index) in this.tutors"
          :key="index">
          <div class="card mb-4">
            <div>
              <div class="card-content" v-if="!isMobile()">
                <div style="width:30%; margin-left: 20px" >
                  <div >
                    <div class="remaining-time-item">
                      {{ tutor.tutor_first_name.toUpperCase() }} {{ tutor.tutor_last_name.toUpperCase() }}
                      <div style="font-size: 16px;">
                      {{ tutor.tutor_bank_name }} <br>
                      {{ tutor.tutor_bank_account }} <br>
                      </div>
                    </div>
                  </div>
                  <div class="view-exam-container">
                    <button class="btn btn-primary" style="width:50%" type="button" @click="openWhatsapp(tutor.tutor_phone_number)">Contactar</button>
                  </div>
                </div>
                <div class="stats-container" style="width:70%">
                  <div class="stat-column" style="width:33%">
                    <p class="stat-number">{{ tutor.userCount }}</p>
                    <p class="stat-title">Alumnos suscritos</p>
                  </div>
                  <div class="stat-column" style="width:33%">
                    <p class="stat-number">${{ tutor.cashAmount }}</p>
                    <p class="stat-title">Comisión actual</p>
                  </div>
                  <div class="stat-column stat-column-date" style="width:33%">
                    <p class="stat-date">{{ payout_date }}</p>
                    <p class="stat-title">Fecha de pago</p>
                  </div>
                </div>
              </div>
              <div class="card-content" v-if="isMobile()">
                
                <div class="stats-container-mobile" style="width: 100%">
                  
                  <div class="stat-column">
                    <p class="stat-number">{{ tutor.userCount }}</p>
                    <p class="stat-title">Alumnos suscritos</p>
                  </div>
                  <div class="stat-column">
                    <p class="stat-number">${{ tutor.cashAmount }}</p>
                    <p class="stat-title">Comisión actual</p>
                  </div>
                  <div class="stat-column stat-column-date">
                    <p class="stat-date">{{ payout_date }}</p>
                    <p class="stat-title">Fecha de pago</p>
                  </div>
                  <div style="width:100%;" >
                    <div >
                      <div>
                        {{ tutor.tutor_first_name.toUpperCase() }} {{ tutor.tutor_last_name.toUpperCase() }}
                        <div style="font-size: 16px;">
                        {{ tutor.tutor_bank_name }} <br>
                        {{ tutor.tutor_bank_account }} <br>
                        </div>
                      </div>
                    </div>
                    <div>
                      <button class="btn btn-primary" style="width:50%" type="button" @click="openWhatsapp(tutor.tutor_phone_number)">Contactar</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          </div>
          
        
</div>
      </div>
    </div>
  </div>
</template>
  
  <script>
  import axios from 'axios';
  import { mapState, mapActions } from 'vuex';

  

  export default {

computed: {
  ...mapState(['tutorEmail', 'tutorId', 'isSubscribedTutor']),


  },

    data() {
      return {
        tutorName: '', // Replace this with actual user's name
        tutorCode: '',
        tutorType: null,
        tutorDate: '',
        payout_date:'',
        tutors:[],
        users: [],
        scoreReport: {
      Total: 0,
      Prueba1: 0,
      Prueba2: 0,
      Aritmetica: 0,
      Algebra: 0,
      Probabilidad: 0,
      Geometria: 0,
      Observar: 0,
      Time_1: 0,
      Time_2: 0,
    },
    stepsLabel:{
        Total: 800,
        Prueba1: 400,
        Prueba2: 400,
        Aritmetica: 100,
        Algebra: 100,
        Probabilidad: 100,
        Geometria: 100,
        Observar: 100
      },
      isDataLoaded: false,
      userImage: require('@/assets/user.png'),
      HeaderLogo: require('@/assets/Mathpaas-white.png')
      };
    },
    mounted() {
  // Fetch the user data
  axios
    .get(`${this.$baseURL}/api/tutor-payouts`)
    .then((response) => {
      const data = response.data.data;

      // Create a map to store unique tutors by their ID
      const tutorMap = new Map();

      data.forEach((tutor) => {
        if (tutorMap.has(tutor.tutor_id)) {
          // If tutor already exists in the map, increment the count
          const existingTutor = tutorMap.get(tutor.tutor_id);
          existingTutor.userCount += 1;
          existingTutor.cashAmount += 100;
        } else {
          // If tutor does not exist, add it to the map with a userCount of 1
          tutorMap.set(tutor.tutor_id, {
            ...tutor,
            userCount: 1,
            cashAmount: 100,
          });
        }
      });

      // Convert the map values back to an array
      this.tutors = Array.from(tutorMap.values());
      this.payoutDate();
      console.log(this.tutors);
      this.isDataLoaded = true;
    });
},


    methods: {

      payoutDate() {
  // Get the current date
  const today = new Date();
  
  // Get the current month (0-indexed, so January is 0)
  const month = today.getMonth();
  
  // Get the current year
  const year = today.getFullYear();
  
  // Calculate the last day of the current month
  const lastDayOfMonth = new Date(year, month + 1, 0).getDate(); // Adding 1 to the month and using 0 to get the last day of the current month
  
  // Get the month name in Spanish
  const monthName = today.toLocaleString('es-ES', { month: 'long' });

  // Format the payout date
  this.payout_date = `${lastDayOfMonth} de ${monthName}, ${year}`;
},

      openWhatsapp(phone) {
        let digitsOnly = phone;

    // Check if phone starts with a '+', remove it
    if (phone.startsWith('+')) {
        digitsOnly = phone.substring(1);
    }

    // Check if phone has 10 digits, prefix with '52'
    if (digitsOnly.length === 10) {
            digitsOnly = '52' + digitsOnly;
        }

    // Construct the URL with the processed phone number
    let url = `https://api.whatsapp.com/send?phone=${digitsOnly}`;

    // Open the URL
    window.open(url, '_blank');
},

      formatDate(dateString) {
        const options = { year: 'numeric', month: 'numeric', day: 'numeric' };
        return new Date(dateString).toLocaleDateString('es-ES', options);
    },

      limitedScoreReport(scoreReport) {
        console.log(scoreReport);
        if(scoreReport!=null){
          const {
        Total,
        Prueba1,
        Prueba2,
        Aritmetica,
        Algebra,
        Probabilidad,
        Geometria,
        //observations,
      } = scoreReport;
      return {
        Total: Total,
        Prueba1: Prueba1,
        Prueba2: Prueba2,
        Aritmetica: Aritmetica,
        Algebra: Algebra,
        Probabilidad: Probabilidad,
        Geometria: Geometria,
        //Observar: observations,
      };
        }
      
    },

    formatTime(seconds) {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes.toString().padStart(2, '0')}:${remainingSeconds.toString().padStart(2, '0')}`;
  },

  viewHistory(email) {
    console.log(email);
  this.$router.push({
    name: "scoreReportHistory",
    query: { email: email } ,
  });
 },

      isMobile() {
    return window.innerWidth <= 768; // Adjust this value based on your needs
  },

      formatLabel(label) {
        const labelMap = {
          'Prueba1': 'Prueba 1',
          'Prueba2': 'Prueba 2',
          'Aritmetica': 'Aritmética',
          'Algebra': 'Álgebra',
          'Probabilidad': 'Probabilidad',
          'Geometria': 'Geometría',
          'Observar': 'Observaciones'
        };
        return labelMap[label] || label;
      },

      ...mapActions(['updateTutorId','updateTutorSubscription', 'updateTutorEmail']),


goToPaymentPlans() {
    // Navigate to the Score Report History view
    this.$router.push("/tutor-payment-plan");
  },

      goToMetrics() {
    // Navigate to the Score Report History view
    this.$router.push("/user-metrics");
  },

      goToScoreReportHistory() {
    // Navigate to the Score Report History view
    this.$router.push("/scoreReportHistory");
  },

      colorByScore(score) {
  const numericScore = Number(score);

  if (numericScore < 40) {
    return "#cc3232"; // red
  } else if (numericScore < 80) {
    return "#e7b416"; // yellow
  } else {
    return "#2dc937"; // green
  }
},
    },
  };
  </script>


  
  <style>

html, body {
  margin: 0;
  padding: 0;
}
  
  * {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.container {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
  }

  .greeting-container {
    flex-grow: 1;
    display: flex;
    justify-content: center !important;
    text-align: center !important;
  }

  .card {
      background-color: white;
    }

  .greeting {
    color: white;
    margin: 0;
  }

  .header-logo {
    height: 50px; /* Adjust the size as needed */
    width: auto;
  }

  .user-image {
    width: 50px; /* Adjust the size as needed */
    height: auto;
  }

  body {
    background: #38b6ff;
  }

  .greeting-card,
  .practice-card,
  .mock-test-card {
    margin-bottom: 20px;
  }

  .greeting-card {
    background-color: rgba(255, 255, 255, 0.8);
  }

  .practice-card,
  .mock-test-card {
    background-color: rgba(255, 255, 255, 0.9);
  }

  .progress-container {
    display: flex;
    justify-content: space-around;
    align-items: flex-start;
    flex: 1;
    flex-wrap: wrap;
    max-height: 100%;
    z-index: 1;
  }

  .stats-container {
    display: flex;
    justify-content: center; /* Center all columns horizontally */
    align-items: center; /* Center all columns vertically */
    text-align: center;
    flex-wrap: nowrap; /* Prevent wrapping of columns */
  }
  
  .stat-column {
    flex: 1;
    padding: 10px;
    display: flex;
    flex-direction: column;
    align-items: center; /* Center content within each column */
  }
  
  .stat-number {
    font-size: 3em;
    font-weight: bold;
    margin: 0;
    text-align: center; /* Center text */
  }

  .stat-date {
    font-size: 1.5em;
    font-weight: bold;
    margin: 0;
    text-align: center; /* Center text */
  }
  
  .stat-title {
    font-size: 1em;
    color: #555;
    margin-top: 5px;
    text-align: center; /* Center text */
  }
  
  .card-content {
    display: flex;
    justify-content: center; /* Center the entire card content horizontally */
    align-items: center; /* Center the entire card content vertically */
  }
  
  .stats-container-mobile {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .stats-container-mobile .stat-column {
    width: 100%;
    text-align: center;
  }
  
  .stat-column-date {
    margin-top: 20px; /* Adds space between the date column and others */
  }
  
  

  .progress-item {
    width: calc(85% / 8); /* Adjust this value based on the total width percentage you want */
    max-width: 80%; /* Adjust this value based on your preferred max-width */
  }

  .text-and-button-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    flex: 1;
    max-width: 15%; /* Adjust this value based on the total width percentage you want */
    margin-right: 0; /* Remove the margin-right */
  }

  .remaining-time-container {
    display: flex;
    justify-content: flex-end;
    flex-direction: column;
    width: 100%;
  }

  .remaining-time-item {
    text-align: left;
    margin-bottom: 0.5rem;
    margin-left: 10px;
  }

  .view-exam-container {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    margin-left: 10px;
  }

  .card-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .view-exam-container button {
    width: 85%;
  }
</style>
