<template>
  <div class="container d-flex align-items-center" style="height: 100vh; width: 100vw;">
      <div class="header">
          <img :src="HeaderLogo" alt="CreateLogo" class="create-logo" style="height:50px; margin-top:20px"/>
          </div>
      <div class="card-container" style="width: 800px; max-width: 95%; margin-top: 20px;">
        <div class="card">
          <div class="card-body">
        <h2 class="card-title text-center mb-4">Crear perfil</h2>
        <form @submit.prevent="createProfile">
          <div class="row">
            <div class="col-md-6 mb-3">
              <input type="text" class="form-control" id="firstName" v-model="firstName" placeholder="Nombre" required>
            </div>
            <div class="col-md-6 mb-3">
              <input type="text" class="form-control" id="lastName" v-model="lastName" placeholder="Apellido" required>
            </div>
          </div>
          <div class="mb-3">
            <input type="email" class="form-control" id="email" v-model="email" placeholder="Correo electrónico" required>
          </div>
          <div class="mb-3">
            <input type="text" class="form-control" id="phoneNumber" v-model="phoneNumber" placeholder="Teléfono">
          </div>
          <div class="mb-3">
            <input type="text" class="form-control" id="country" v-model="country" placeholder="País">
          </div>
          <div class="mb-3">
            <input type="text" class="form-control" id="state" v-model="state" placeholder="Estado">
          </div>
          <div class="mb-3">
            <input type="text" class="form-control" id="city" v-model="city" placeholder="Ciudad">
          </div>
          <div class="mb-3">
            <input type="text" class="form-control" id="highSchool" v-model="highSchool" placeholder="Escuela">
          </div>
          <div class="mb-3">
            <input type="text" class="form-control" id="tutor_code" v-model="tutor_code" placeholder="Codigo de maestro">
          </div>
          <div class="mb-3">
            <input class="form-checkbox" type="checkbox" id="TermsYes" value="Yes" v-model="terms" style="margin-top: 20px; margin-right: 10px">
            <label style="margin-top: 17px" class="form-checkbox-label" for="TermsYes">
                Acepto los 
                <a href="https://www.mathpaas.com/terms-conditions" target="_blank">términos y condiciones</a>
            </label>
          </div>
          <button type="submit" class="btn btn-primary">Crear perfil</button>
        </form>
      </div>
    </div>
  </div>
</div>
      
</template>
  
  <script>
  import axios from 'axios';
  
  import useFacebookPixel from '@/composables/useFacebookPixel';
  import { onMounted } from 'vue';


export default {
  setup() {
    const { track } = useFacebookPixel();

    onMounted(() => {
      track('CompleteRegistration', { content_name: 'Create Profile' });
    });

    return {};
  },    data() {
      return {
        firstName: '',
        lastName: '',
        email: '', // You should fetch the authenticated user's email and populate this field
        phoneNumber: '',
        country: '',
        state: '',
        city: '',
        highSchool: '',
        tutor_code: '',
        userId: '',
        HeaderLogo: require('@/assets/Mathpaas-white.png'),
        terms:''
      };
    },
    methods: {
      async createProfile() {
        if(this.terms){

          try {
          const userProfile = {
            first_name: this.firstName,
            last_name: this.lastName,
            email: this.email,
            phone_number: this.phoneNumber,
            country: this.country,
            state: this.state,
            city: this.city,
            high_school: this.highSchool,
            tutor_code: this.tutor_code
          };
          await axios.post(`${this.$baseURL}/api/create-profile`, userProfile).then(() => {
      const userSubs = {
            email: this.email,
            subscription: 0,
            open_answer_credits: 10,
            multiple_choice_credits: 10,
            stripe_customer_id: null,
            next_charge_date: null,
          };
          axios.post(`${this.$baseURL}/api/user_subscription`, userSubs).then(() => {
          this.$router.push('/student-dashboard');
    })
    .catch((error) => {
      console.error("Error creating user subscription:", error);
    });
    })
    .catch((error) => {
      console.error("Error creating user profile:", error);
    });
           
        } catch (error) {
          console.error('Error creating profile:', error);
        }
        }
        
      },
    },
  };
  </script>
  
  <style>
  body {
    background: #38b6ff;
  }

  .card {
      background-color: white;
    }

  
  </style>