<template>
  <div class="container" v-if="isDataLoaded">
    <div class="header" style="margin-top: 10px;">
          <img :src="HeaderLogo" alt="HeaderLogo" class="header-logo" />
          <router-link to="user-profile">
            <img :src="userImage" alt="User" class="user-image" />
          </router-link>
          </div>
    <!-- Total_scores graph -->
    <div class="row">
      <div
        class="col-md-12"
        v-for="(metric, index) in chartMetrics.slice(0, 1)"
        :key="index"
      >
        <div class="card mb-4">
          <div class="card-body">
            <h5 class="card-title">{{ metric.name }}</h5>
            <line-chart
              :chart-data="metric.chartData"
              :options="metric.options"
              style="height: 250px;"
            ></line-chart>
          </div>
        </div>
      </div>
    </div>
    <!-- Section scores and time graphs -->
    <div class="row">
      <div
        class="col-md-6"
        v-for="(metric, index) in [chartMetrics[1], chartMetrics[7]]"
        :key="index"
      >
        <div class="card mb-4">
          <div class="card-body">
            <h5 class="card-title">{{ metric.name }}</h5>
            <line-chart
              :chart-data="metric.chartData"
              :options="metric.options"
              style="height: 250px;"
            ></line-chart>
            <div class="legend" v-if="metric.legend">
              <div
                class="legend-item"
                v-for="(item, idx) in metric.legend"
                :key="idx"
              >
                <span
                  :style="{
                    backgroundColor: metric.chartData.datasets[idx].borderColor,
                  }"
                  class="legend-color"
                ></span>
                <span class="legend-label">{{ item }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Aritmetic and Algebra graphs -->
    <div class="row">
      <div
        class="col-md-6"
        v-for="(metric, index) in chartMetrics.slice(2, 6)"
        :key="index"
      >
        <div class="card mb-4">
          <div class="card-body">
            <h5 class="card-title">{{ metric.name }}</h5>
            <line-chart
              :chart-data="metric.chartData"
              :options="metric.options"
              style="height: 250px;"
            ></line-chart>
          </div>
        </div>
      </div>
    </div>
    <!-- Remaining 3 graphs 
    <div class="row">
      <div
        class="col-md-4"
        v-for="(metric, index) in chartMetrics.slice(4, 7)"
        :key="index"
      >
        <div class="card mb-4">
          <div class="card-body">
            <h5 class="card-title">{{ metric.name }}</h5>
            <line-chart
              :chart-data="metric.chartData"
              :options="metric.options"
              style="height: 250px;"
            ></line-chart>
          </div>
        </div>
      </div>
    </div>-->
  </div>
  <div v-else>Loading...</div>
</template>




<script>
import { defineComponent } from 'vue';
import axios from 'axios';
import { mapState } from "vuex";
import { Chart, LineController, LineElement, PointElement, LinearScale, CategoryScale } from 'chart.js';
import { LineChart } from 'vue-chart-3';
import 'chartjs-adapter-date-fns'; // If you plan to use time series data

Chart.register(LineController, LineElement, PointElement, LinearScale, CategoryScale);

export default defineComponent({
  components: {
    LineChart,
  },

  computed:{
    ...mapState(["email"]),
  },
  data() {
    return {
      isDataLoaded: false,
      scoreReports: [],
      chartMetrics: [],
      userImage: require('@/assets/user.png'),
      HeaderLogo: require('@/assets/Mathpaas-white.png')
    };
  },
  mounted() {
    // Fetch the user data
    axios
      .get(`${this.$baseURL}/api/users/user-data`, {
        params: {
          email: this.email,
        },
      })
      .then((response) => {
        const user_id = response.data.data.id;
        // Fetch score reports
        axios
          .get(`${this.$baseURL}/api/all_score_reports`, {
            params: {
              user_id: user_id,
            },
          })
          .then((response) => {
            this.scoreReports = response.data.data;
            this.createChartData();
            this.isDataLoaded = true;
          })
          .catch((error) => {
            console.error("Error fetching score reports:", error);
          });
      })
      .catch((error) => {
        console.error("Error fetching user data:", error);
      });
  },
  methods: {

    createChartOptions(stepSize, displayLegend = false) {
  return {
    plugins: {
      legend: {
        display: displayLegend, // Use the provided parameter to control the legend display
      },
    },
    scales: {
      x: {
        grid: {
          display: false,
        },
        ticks: {
          display: false, // Add this line to hide the x-axis labels
        },
      },
      y: {
        grid: {
          display: true,
        },
        ticks: {
          stepSize: stepSize,
          maxTicksLimit: 5,
        },
      },
    },
  };
},



    createChartData() {
  
  
      const metricKeys = [
  'total_score',
  'section_scores', // Combined section scores
  'aritmetic',
  'algebra',
  'data_analysis',
  'geometry',
  'observations',
  'remaining_time', // Combined remaining time
];

const metricNames = [
  'Puntuación Total',
  'Puntuación por Sección', // Combined section scores
  'Aritmética',
  'Álgebra',
  'Probabilidad',
  'Geometría',
  'Observar',
  'Tiempo por Sección', // Combined remaining time
];

const metricStepSizes = [
  100, // total_score
  50,  // section_scores
  25,  // aritmetic
  25,  // algebra
  25,  // data_analysis
  25,  // geometry
  25,  // observations
  10, // remaining_time
];



this.chartMetrics = metricKeys.map((key, index) => {
  let chartData;

  if (key === 'section_scores' || key === 'remaining_time') {
    const section1Key = key === 'section_scores' ? 'section1_score' : 'section1_remaining_time';
    const section2Key = key === 'section_scores' ? 'section2_score' : 'section2_remaining_time';

    chartData = {
      labels: this.scoreReports.map((_, i) => i + 1),
      datasets: [
        {
          label: 'Seccion 1',
          data: this.scoreReports.map((report) => {
            // Convert seconds to minutes for remaining_time
            return key === 'remaining_time' ? report[section1Key] / 60 : report[section1Key];
          }),
          borderColor: '#007bf6',
          fill: false,
          pointRadius: 0,
        },
        {
          label: 'Seccion 2',
          data: this.scoreReports.map((report) => {
            // Convert seconds to minutes for remaining_time
            return key === 'remaining_time' ? report[section2Key] / 60 : report[section2Key];
          }),
          borderColor: '#09d4ff', // Change the color for the second section
          fill: false,
          pointRadius: 0,
        },
      ],
    };
  } else {
    chartData = {
      labels: this.scoreReports.map((_, i) => i + 1),
      datasets: [
        {
          label: metricNames[index],
          data: this.scoreReports.map((report) => report[key]),
          borderColor: '#007bf6',
          fill: false,
          pointRadius: 0,
        },
      ],
    };
  }

  const stepSize = metricStepSizes[index];
  const options = (key === 'section_scores' || key === 'remaining_time') ? this.createChartOptions(stepSize, true) : this.createChartOptions(stepSize);

  const legend = (key === 'section_scores' || key === 'remaining_time') ? ['Sección 1', 'Sección 2'] : null;

  return {
    name: metricNames[index],
    chartData,
options,
legend, // Add this property
};
});


},
  },
});
</script>

<style>
body {
  background: #38b6ff;
}

.card {
  background-color: white;
  margin-bottom: 10px; /* Reduce the margin to make cards closer */
  padding: 10px; /* Reduce the padding to make cards smaller */
}

.line-chart {
  min-height: 200px; /* Reduce the min-height to make the chart area smaller */
}

.legend {
  display: flex;
  justify-content: center;
  margin-top: 10px;
}

.legend-item {
  display: flex;
  align-items: center;
  margin: 0 10px;
}

.legend-color {
  display: inline-block;
  width: 14px;
  height: 14px;
  border-radius: 50%;
  margin-right: 5px;
}

.legend-label {
  font-size: 14px;
}
</style>
