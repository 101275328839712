import { createRouter, createWebHistory } from 'vue-router';
import CreateProfile from '@/views/CreateProfile.vue';
import CreateTutor from '@/views/CreateTutor.vue';

import SignIn from '@/views/SignIn.vue';
import TutorSignIn from '@/views/TutorSignIn.vue';

import SignUp from '@/views/SignUp.vue';
import TutorSignUp from '@/views/TutorSignUp.vue';

import studentDashboard from '@/views/studentDashboard.vue';
import TutorDashboard from '@/views/TutorDashboard.vue';
import AdminDashboard from '@/views/AdminDashboard.vue';


import Section1Training from '@/views/Section1Training.vue';
import Section2Training from '@/views/Section2Training.vue';
import MockTest from '@/views/MockTest.vue';
import scoreReportHistory from '@/views/scoreReportHistory.vue';
import UserMetrics from '@/views/UserMetrics.vue';
import PreviousExam from '@/views/PreviousExam.vue';
import UserProfile from '@/views/UserProfile.vue';
import TutorProfile from '@/views/TutorProfile.vue';
import TutorPayouts from '@/views/TutorPayouts.vue';


import ChangePassword from '@/views/ChangePassword.vue';
import SubscriptionSuccess from '@/views/SubscriptionSuccess.vue';
import PaymentPlan from '@/views/PaymentPlan.vue';
import TutorPaymentPlan from '@/views/TutorPaymentPlan.vue';

import LandingPage from '@/views/LandingPage.vue';
import EditProblems from '@/views/EditProblems.vue';
import PrivacyAdvice from '@/views/PrivacyAdvice.vue';
import TermsConditions from '@/views/TermsConditions.vue';






const routes = [
  {
    path: '/',
    name: 'LandingPage',
    component: LandingPage
  },
  {
    path: '/signup',
    name: 'SignUp',
    component: SignUp
  },
  {
    path: '/tutor-signup',
    name: 'TutorSignUp',
    component: TutorSignUp
  },
  {
    path: '/signin',
    name: 'SignIn',
    component: SignIn
  },
  {
    path: '/tutor-signin',
    name: 'TutorSignIn',
    component: TutorSignIn
  },
  {
    path: '/create-profile',
    name: 'CreateProfile',
    component: CreateProfile,
  },
  {
    path: '/create-tutor',
    name: 'CreateTutor',
    component: CreateTutor,
  },
  {
    path: '/student-dashboard',
    name: 'studentDashboard',
    component: studentDashboard,
  },
  {
    path: '/tutor-dashboard',
    name: 'TutorDashboard',
    component: TutorDashboard,
  },
  {
    path: '/section1training',
    name: 'Section1Training',
    component: Section1Training,
  },

  {
    path: '/section2training',
    name: 'Section2Training',
    component: Section2Training,
  },

  {
    path: '/mockTest',
    name: 'MockTest',
    component: MockTest,
  },

  {
    path: '/scoreReportHistory',
    name: 'scoreReportHistory',
    component: scoreReportHistory,
  },

  {
    path: '/user-metrics',
    name: 'UserMetrics',
    component: UserMetrics,
  },

  {
    path: "/previous-exam/:mock_test_id",
    name: "PreviousExam",
    component: PreviousExam,
  },

  {
    path: "/user-profile",
    name: "UserProfile",
    component: UserProfile,
  },

  {
    path: "/tutor-profile",
    name: "TutorProfile",
    component: TutorProfile,
  },
  {
    path: "/tutor-payouts",
    name: "TutorPayouts",
    component: TutorPayouts,
  },

  {
    path: "/change-password",
    name: "ChangePassword",
    component: ChangePassword,
  },

  {
    path: '/subscription-success',
    name: 'SubscriptionSuccess',
    component: SubscriptionSuccess,
  },

  {
    path: '/payment-plan',
    name: 'PaymentPlan',
    component: PaymentPlan,
  },

  {
    path: '/tutor-payment-plan',
    name: 'TutorPaymentPlan',
    component: TutorPaymentPlan,
  },

  {
    path: "/edit-problems/:admin_password",
    name: "EditProblems",
    component: EditProblems,
  },
  {
    path: "/admin-dashboard/:admin_password",
    name: "AdminDashboard",
    component: AdminDashboard,
  },
  {
    path: "/privacy-advice",
    name: "PrivacyAdvice",
    component: PrivacyAdvice,
  },
  {
    path: "/terms-conditions",
    name: "TermsConditions",
    component: TermsConditions,
  },
  
];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
  });

export default router;