<template>
  <div class="container">
    <div class="header" style="margin-top: 10px;">
      <img :src="HeaderLogo" alt="HeaderLogo" class="header-logo" />
      <router-link to="../user-profile">
        <img :src="userImage" alt="User" class="user-image" />
      </router-link>
    </div>
    <div class="row">
      <div class="col-12">


        <div class="card" style=" height: 30vh">
          <div class="card-body">
            <div style="width:100%">
              Buscar problemas
              <input type="text" class="form-control" id="filter-text" v-model="filterText" placeholder="Ingresa el texto del problema" @input="filterProblems">
            </div>          
          </div>
          <div class="card" style="overflow-y: auto; height: 25vh;">
            <div class="card-body">
              <ul class="list-group">
                <li class="d-flex justify-content-left align-items-left"
                    v-for="(problem, index) in this.filteredProblems" :key="index"
                    @click="updateCurrentProblemIndex(problem.id)">
                  {{ problem.id }} {{ problem.problem }}
                </li>
              </ul>
            </div>
          </div>
        </div>


        <div class="card" style="min-height: 80vh">
          <div class="card-body">
            <h1 class="card-title" style="margin-top: 20px; margin-bottom: 20px;">
              Simulador de Problemas
            </h1>
            <div>
              <h5 class="card-subtitle" style="margin-top: 20px; margin-bottom: 20px">
                Problema {{ currentProblemIndex + 1 }} de {{ totalProblems }}
              </h5>
              <h5  class="card-subtitle" style="margin-top: 20px; margin-bottom: 20px">{{currentProblem.topic}}</h5>
              <h5  class="card-subtitle" style="margin-top: 20px; margin-bottom: 20px">{{currentProblem.category}}</h5>
              <h5  class="card-subtitle" style="margin-top: 20px; margin-bottom: 20px">{{currentProblem.sub_category}}</h5>
              <h5  class="card-subtitle" style="margin-top: 20px; margin-bottom: 20px">{{currentProblem.use_case}}</h5>
              <div class="problem-container">
                <img v-show='currentProblem.has_figure==1' class="problem-image" :src="`https://mathpaas-documents.s3.amazonaws.com/images/${currentProblem.draw}.png`" alt="Assessment image">
                <div v-if="isLoading" class="loading-animation">
                  <div class="spinner-border text-primary" role="status"></div>
                </div>
                <template v-else>
                  <div :class="!isMobile() ? 'formatted-text-wrapper' : 'formatted-text-wrapper-mobile'">
                    <FormattedText :content="currentProblem.problem"/>
                  </div>
                  <div class="options" style="margin-top: 30px;" v-if="currentProblem.is_open_answer==0">
                    <div class="form-check" v-for="(option, index) in currentProblem.OptionLabels" :key="index" :class="isSelected(index)">
                      <input class="form-check-input" type="radio" name="option" :id="'option' + index" :value="index" v-model="selectedOption" disabled />
                        <label style="margin-right: 10px;" class="form-check-label" :for="'option' + index">
                          <div>
                            <FormattedText :content="option.label + '. ' + option.text"/>
                          </div>
                        </label>
                    </div>
                  </div>
                  <div v-else class="answer-input" style="margin-top: 30px;">
                    <div class="form-group">
                      <input type="text" class="form-control" id="userAnswer" v-model="userAnswer" placeholder="Sin respuesta" disabled :class="textBoxBackgroundColor" />
                    </div>
                  </div>
                </template>
                <div class="buttons d-flex flex-column" v-if="!isLoading && !isMobile()">
                  <div class="submit-button-container mb-3">
                      <button class="btn btn-info equal-width text-white" @click="showHintInModal">Pistas ({{ this.problems[currentProblemIndex].remainingHints }})</button>
                      <button class="btn btn-secondary equal-width" @click="showExplanationInModal">Ayuda</button>
                  </div>
                  <div class="d-flex justify-content-between align-items-center" style="width: 100%;">
                    <button class="btn btn-secondary equal-width" @click="previousProblem">
                      Anterior
                    </button>
                    <div class="input-group" style="width:200px">
                      <input type="text" class="form-control" id="problem-category" v-model="this.toCurrentProblemIndex" placeholder="#Problema">
                      <button type="button" class="btn btn-primary" @click="goToProblem()">Buscar</button>
                    </div>
                    <button class="btn btn-secondary equal-width" @click="nextProblem">
                      Siguiente
                    </button>
                  </div>
                </div>
                <div class="buttons d-flex flex-column " style="width: 100%;" v-if="!isLoading && isMobile()">
                  <div>
                      <button style="width: 45%; height: 40px;" class="btn btn-info equal-width text-white" @click="showHintInModal">Pistas ({{ this.problems[currentProblemIndex].remainingHints }})</button>
                      <button style="width: 45%; height: 40px;" class="btn btn-secondary equal-width" @click="showExplanationInModal">Ayuda</button>
                  </div>
                  <div class="input-group" style="width:100%; height: 40px;margin-top: 20px;">
                      <input type="text" class="form-control" id="problem-category" v-model="this.toCurrentProblemIndex" placeholder="#Problema">
                      <button type="button" class="btn btn-primary" @click="goToProblem()">Buscar</button>
                    </div>
                  <div  style="width: 100%; margin-top: 20px;">
                    <button class="btn btn-secondary equal-width" style="width: 45%; height: 40px;" @click="previousProblem">
                      Anterior
                    </button>
                    <button class="btn btn-secondary equal-width" style="width: 45%; height: 40px " @click="nextProblem">
                      Siguiente
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
              <div class="card mb-2">
                <div class="card-body">
                  <h5 class="card-title text-center mb-4">Editor de Problemas</h5>
                  <h5 class="card-title text-center mb-4">Problema ID #{{ currentProblem.id }}</h5>
                  <form @submit.prevent="updateProblem">
                    <div class="mb-3" style="text-align:left;">
                      Materia:
                      <input type="text" class="form-control" id="problem-topic" v-model="currentProblem.topic" placeholder="Escribe la materia del problema">
                    </div>
                    <div class="mb-3" style="text-align:left;">
                      Categoria:
                      <input type="text" class="form-control" id="problem-category" v-model="currentProblem.category" placeholder="Escribe la categoria del problema">
                    </div>
                    <div class="mb-3" style="text-align:left;">
                      Subcategoria:
                      <input type="text" class="form-control" id="problem-sub-category" v-model="currentProblem.sub_category" placeholder="Escribe la subcategoria del problema">
                    </div>
                    <div class="mb-3" style="text-align:left;">
                      Caso de uso:
                      <input type="text" class="form-control" id="problem-use-case" v-model="currentProblem.use_case" placeholder="Escribe el caso de uso especifico">
                    </div>
                    <div v-if="currentProblem.has_figure==1" class="mb-3" style="text-align:left;">
                      Imagen:
                      <input type="text" class="form-control" id="problem-category" v-model="currentProblem.draw" placeholder="Escribe el nombre de la imagen">
                    </div>
                    <div class="mb-3" style="text-align:left;">
                      Problema:
                      <textarea type="text" class="form-control" id="problem-text" v-model="currentProblem.problem" placeholder="Escribe el texto del problema en formato LaTex" style="height:200px"></textarea>
                    </div>
                    <div class="mb-3" style="text-align:left;">
                      Explicacion:
                      <textarea type="text" class="form-control" id="problem-explanation" v-model="currentProblem.explanation" placeholder="Escribe la explicacion en formato LaTex" style="height:200px"></textarea>
                    </div>
                    <div class="mb-3">
                      <div class="input-group">
                        <input type="text" class="form-control" id="problem-hints" v-model="hint" placeholder="Ingresa una pista">
                        <button class="btn btn-primary" type="button" @click="addHint">Agregar</button>
                      </div>
                      <ul class="list-group mt-3">
                        <li class="list-group-item d-flex justify-content-between align-items-center" v-for="(hint, index) in currentProblem.Hints" :key="index">
                          {{ hint }}
                          <button type="button" class="btn-close" @click="removeHint(index)"></button>
                        </li>
                      </ul>
                    </div>
                    <div v-if="currentProblem.is_open_answer==0" style="text-align:left;">
                      Opciones (Opcion multiple):
                      <div v-for="(option,index) in currentProblem.OptionLabels" :key="index">
                        <div class="mb-3">
                          <li class="list-group-item d-flex justify-content-between align-items-center">
                            {{ option.label }}.
                            <input type="text" class="form-control" id="problem-option" v-model="option.text" placeholder="Escribe el valor de la opcion en formato LaTex">
                           </li>
                        </div>
                      </div>
                      <div class="mb-3" style="text-align:left;">
                        Respuesta (Opcion multiple): 
                        <div class="input-group">
                        <select class="form-control" id="answer" v-model="currentProblem.answer">
                          <option value="0">0</option>
                          <option value="1">1</option>
                          <option value="2">2</option>
                          <option value="3">3</option>
                          <option value="4">4</option>
                        </select>
                        </div>
                      </div>
                    </div>
                    <div v-if="currentProblem.is_open_answer==1" style="text-align:left;">
                      Variantes:
                      <div class="mb-3">
                      <div class="input-group">
                        <input type="text" class="form-control" id="problem-variations" v-model="variation" placeholder="Ingresa una variante sin usar formato LaTex">
                        <button class="btn btn-primary" type="button" @click="addVariation">Agregar</button>
                      </div>
                      <ul class="list-group mt-3">
                        <li class="list-group-item d-flex justify-content-between align-items-center" v-for="(variation, index) in currentProblem.Variations" :key="index">
                          {{ variation }}
                          <button type="button" class="btn-close" @click="removeVariation(index)"></button>
                        </li>
                      </ul>
                    </div>
                    <div class="mb-3" style="text-align:left;">
                      Respuesta:
                      <input type="text" class="form-control" id="problem-answer-open" v-model="currentProblem.answer" placeholder="Escribe la respuesta sin usar Formato Latex">
                    </div>
                    </div>
                    <button style="margin-top:20px" type="submit" class="btn btn-primary">Actualizar problema</button>
                  </form>
                </div>
              </div>
            </div>
          </div>
          <div class="modal fade" id="resultModal" tabindex="-1" aria-labelledby="resultModalLabel" aria-hidden="true">
            <div class="modal-dialog">
              <div class="modal-content">
                <div class="modal-header">
                  <h5 class="modal-title" id="resultModalLabel">{{ modalTitle }}</h5>
                  <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                  <div v-if="!isChatMode">
                    <FormattedText :content="modalMessage"/>
                  </div>
                  <div v-else class="chat-container">
                    <div class="messages">
                      <div v-for="(message, index) in chatMessages" :key="index" class="message">
                        <div :class="message.sender === 'user' ? 'user-message' : 'assistant-message'">
                          <FormattedText :content="message.content"/>
                        </div>
                      </div>
                      <div v-if="isWriting" class="message">
                        <div class="assistant-message" style="width: 60px; height: 40px;">
                          <div class="loader" style="margin-top: -10px;"></div>
                        </div>
                      </div>
                    </div>
                    <div class="input-area">
                      <input v-model="userInput" @keyup.enter="sendMessage" type="text" class="form-control" placeholder="Type your message here...">
                      <button @click="sendMessage" class="btn btn-primary">Enviar</button>
                    </div>
                  </div>
                </div>
                <div class="modal-footer">
                  <button type="button" class="btn btn-secondary" data-bs-dismiss="modal" @click="closeModal" v-if="showContinueButton">Continuar</button>
                  <button type="button" class="btn btn-primary" @click="fetchAndCloseModal" v-if="showNextProblemButton">Siguiente</button>
                  <button type="button" class="btn btn-primary" @click="showExplanationAfterHints" v-if="showExplanationButton">Ayuda</button>  
              </div>
            </div>
          </div>
        </div>
      </div>
</template>


<script>
import axios from 'axios';
//import { mapState } from 'vuex';
import FormattedText from "@/components/FormattedText.vue";
import { Modal } from "bootstrap";


export default {

  name: "PreviousExam",

  components: {
      FormattedText,
  },

  created() {
this.fetchDBProblems().then(() => {
  this.setSelectedOptionAndUserAnswer();
});
  },

  computed: {


currentProblem() {
return this.problems[this.currentProblemIndex] || null;
},


},

  data() {
  return {
    problems: [],
    filteredProblems: [],
    filterText: "",
    hint:'',
    variation:'',
    toCurrentProblemIndex:'',
    selectedOption:'',
    userAnswer: '',
    currentProblemIndex: 0,
    isLoading: false,
    totalProblems: 0,
    isDataLoaded: false,
    modalTitle: '',
      modalMessage: '',
      showContinueButton: false,
      showExplanationButton: false,
      showNextProblemButton: false,
      shouldFetchNewProblem: false,
      isChatMode: false,
      userInput: '',
      chatMessages: [],
      isWriting: false,
      userImage: require('@/assets/user.png'),
      HeaderLogo: require('@/assets/Mathpaas-white.png')
  };
},


  methods: {

    filterProblems() {

      console.log("filtering")

      if (this.filterText !== "") {
      const searchText = this.filterText.toLowerCase();
      this.filteredProblems = this.problems.filter(problem => 
        problem.problem.toLowerCase().includes(searchText)
      ).slice(0, 10);
    } 

    console.log(this.filteredProblems);

      
    },

    async updateProblem() {

      let tempOptions = [];
      let tempVariations = [];
      if(this.problems[this.currentProblemIndex].is_open_answer==0){
        this.problems[this.currentProblemIndex].OptionLabels.forEach(jsonObject => {
        tempOptions.push(jsonObject.text);
        });
        tempOptions = JSON.stringify(tempOptions);
        tempVariations = null;
      } else {
        tempOptions = null;
        this.problems[this.currentProblemIndex].Variations.forEach(jsonObject => {
        tempVariations.push(jsonObject);
        });
        tempVariations = JSON.stringify(tempVariations);
      }
      

    try{
        const problemUpdate = {
            id: this.problems[this.currentProblemIndex].id,
            topic: this.problems[this.currentProblemIndex].topic,
            category: this.problems[this.currentProblemIndex].category,
            sub_category: this.problems[this.currentProblemIndex].sub_category,
            use_case: this.problems[this.currentProblemIndex].use_case,
            problem: this.problems[this.currentProblemIndex].problem,
            explanation: this.problems[this.currentProblemIndex].explanation,
            hints: JSON.stringify(this.problems[this.currentProblemIndex].Hints),
            options: tempOptions,
            variations: tempVariations,
            answer: this.problems[this.currentProblemIndex].answer,
            draw: this.problems[this.currentProblemIndex].draw
        }
        console.log(problemUpdate);
        await axios.put(`${this.$baseURL}/api/update-problem`, problemUpdate );
        alert('Problem updated successfully');
    } catch(error) {
      alert('Error updating problem');
        console.error('Error updating updating problem');
    }
},

    addHint() {
        if (this.hint) {
          this.problems[this.currentProblemIndex].Hints.push(this.hint);
          this.hint = '';
        }
      },
  
      removeHint(index) {
        this.problems[this.currentProblemIndex].Hints.splice(index, 1);
      },

      addVariation() {
        if (this.variation) {
          this.problems[this.currentProblemIndex].Variations.push(this.variation);
          this.variation = '';
        }
      },
  
      removeVariation(index) {
        this.problems[this.currentProblemIndex].Variations.splice(index, 1);
      },


    isMobile() {
  return window.innerWidth <= 768; // Adjust this value based on your needs
},

      async fetchGPTResponse() {

this.isWriting = true;

try {
const conversation = this.chatMessages;
const problem = this.problem;
const response = await axios.get(`${this.$baseURL}/api/generate-response`, {
  params: { conversation, problem },
});

if (response.data.success) {
    this.isWriting = false;

    return response.data.response;

} else {
  throw new Error('Error fetching GPTResponse');
}
} catch (error) {
console.error(error);
}
},

      async sendMessage() {
  if (this.userInput.trim() === '') return;
  this.chatMessages.push({ sender: 'user', content: this.userInput });
  
  const gptPrompt = this.chatMessages;
  this.userInput = '';

  const gptResponse = await this.fetchGPTResponse(gptPrompt.response);
  this.chatMessages.push({ sender: 'assistant', content: gptResponse });
},

      closeModal() {
    const modal = Modal.getInstance(document.getElementById("resultModal"));
    modal.hide();
    const body = document.getElementsByTagName("body")[0];
    body.classList.remove("modal-open");
  },

  showModal(title, message) {
  this.modalTitle = title;
  this.modalMessage = message;
  const modal = new Modal(document.getElementById("resultModal"));
  modal.show();
},

fetchAndCloseModal() {
  const modal = Modal.getInstance(document.getElementById("resultModal"));
  modal.hide();
  this.nextProblem();
},

showExplanationAfterHints(){
  const modal = Modal.getInstance(document.getElementById("resultModal"));
  modal.hide();
  this.showExplanationInModal();
},

  showExplanationInModal() {
  this.chatMessages=[],
  this.isChatMode = true;
  this.modalTitle = "Asistente"
  this.showModal(this.modalTitle, this.problems[this.currentProblemIndex].explanation);
  this.showNextProblemButton = true;
  this.showExplanationButton = false;
  this.showContinueButton = false;
this.chatMessages.push({ sender: 'assistant', content: this.problems[this.currentProblemIndex].explanation });
this.chatMessages.push({ sender: 'assistant', content: 'Tienes alguna duda?' });
},

showHintInModal() {
  this.isChatMode = false;
if (this.problems[this.currentProblemIndex].remainingHints == 0) {
  this.showModal("Pista", "Ya no hay mas pistas. Da clic en el boton de ayuda para recibir una explicacion detallada de nuestro asistente virtual.");
  this.showNextProblemButton = false;
  this.showExplanationButton = true;
  this.showContinueButton = true;
} else {
  this.showModal("Pista", this.problems[this.currentProblemIndex].Hints[this.problems[this.currentProblemIndex].Hints.length - this.problems[this.currentProblemIndex].remainingHints]);
  this.problems[this.currentProblemIndex].remainingHints -= 1;
  this.showNextProblemButton = false;
  this.showExplanationButton = false;
  this.showContinueButton = true;
}
},

setSelectedOptionAndUserAnswer() {
  this.userAnswer = this.problems[this.currentProblemIndex].answer;
  this.selectedOption = this.problems[this.currentProblemIndex].answer;
},

async fetchDBProblems() {
      if (this.$route.params.admin_password == 'MayaLuna107'){
      this.isLoading = true;
      this.remainingHints = 0;
      this.selectedOption = null;

      try {
        
        const response = await axios.get(`${this.$baseURL}/api/get-all-problems`);
        if (response.data.success) {

        const jsonObjectArray = response.data.data;
        this.totalProblems = jsonObjectArray.length;

        jsonObjectArray.forEach(jsonObject => {
        const tempHints = JSON.parse(jsonObject.hints);
        const remainingHints = tempHints.length;
        Object.assign(jsonObject, { Hints: tempHints});
        Object.assign(jsonObject, { remainingHints: remainingHints});
        if (jsonObject.options != null) {
        const tempOptions = JSON.parse(jsonObject.options);
        const optionLabels = Array.from({ length: tempOptions.length }, (_, i) => String.fromCharCode(65 + i));
        const options = tempOptions.map((option, index) => {
          return {
            label: optionLabels[index],
            text: option,
          };
        });
        // Add OptionLabels property to jsonObject
        Object.assign(jsonObject, { OptionLabels: options });
      } else {
        const tempVariations = JSON.parse(jsonObject.variations);
        Object.assign(jsonObject, { Variations: tempVariations});
      }
      this.problems.push(jsonObject);
      this.totalProblems = this.problems.length;
      this.isLoading = false;
    });
    

        } else {
          throw new Error('Error fetching problems');
        }
      } catch (error) {
        console.error(error);
      }
    }
  },

  updateCurrentProblemIndex(clickedProblemId) {
      const foundIndex = this.problems.findIndex(p => p.id === clickedProblemId);
      if (foundIndex !== -1) {
        this.currentProblemIndex = foundIndex;
      }
      this.setSelectedOptionAndUserAnswer();
    },


goToProblem() {
    if(this.toCurrentProblemIndex>0 || this.toCurrentProblemIndex <= this.totalProblems){
      this.currentProblemIndex = this.toCurrentProblemIndex-1;
    this.setSelectedOptionAndUserAnswer();
    console.log(this.problems[this.currentProblemIndex]);
}
},

    isSelected(index) {
const correct = this.currentProblem.correct === 1;
const selected = this.currentProblem.user_answer!=undefined && parseInt(this.currentProblem.user_answer) === index;
return {
  'selected-correct': correct && selected,
  'selected-wrong': !correct && selected,
};
},

nextProblem() {
if (this.currentProblemIndex < this.totalProblems - 1) {
  this.currentProblemIndex += 1;

} this.setSelectedOptionAndUserAnswer();
},

previousProblem() {
  if (this.currentProblemIndex > 0) {
    this.currentProblemIndex -= 1;

} this.setSelectedOptionAndUserAnswer();

},


},
};
</script>

<style scoped>

.problem-image {
  width: auto;    /* Set the width of the image to 40% of the container */
  height: 250px;  /* Maintain the aspect ratio */
}
.container {
    height: 100vh;
    user-select: none;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
  }
.buttons-right {
display: flex;
justify-content: flex-end;
}

.buttons-left {
display: flex;
justify-content: flex-start;
}


.selected-correct {
background-color: #c9e9cf; /* Change the color to your preference */
border-radius: 10px;
}

.selected-wrong {
background-color: #f5b2b2; /* Change the color to your preference */
border-radius: 10px;
}

.text-correct {
background-color: #c9e9cf !important; /* Change the color to your preference */
}

.text-wrong {
background-color: #f5b2b2 !important; /* Change the color to your preference */
}

.thumbnails-container {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin-top: 20px;
}

.thumbnail-btn {
  margin: 5px;
  min-height: 45px;
  min-width: 45px;
  max-width: 45px;
  max-height: 45px;
  text-align: center;
}

.card {
    background-color: white;
  }

</style>