<template>
    <div class="container" style="height: 100vh; width: 100vw;">
      <div class="header" style="margin-top: 10px;">
          <img :src="HeaderLogo" alt="HeaderLogo" class="header-logo" />
          <router-link to="user-profile">
            <img :src="userImage" alt="User" class="user-image" />
          </router-link>
          </div>
      <div class="card-container d-flex justify-content-center align-items-center">
        <div class="card" style="width: 700px;">
        <div class="card-body">
          <h2 class="card-title text-center mb-4">Perfil de Usuario</h2>
          <div class="row" style="text-align: left;">
            <div class="col-md-4 mb-3" >
              <label for="firstName" >Nombre:</label>
            </div>
            <div class="col-md-8 mb-3">
              <input type="text" class="form-control" id="firstName" v-model="firstName" :readonly="!profileEditMode">
            </div>
            <div class="col-md-4 mb-3">
              <label for="lastName">Apellido:</label>
            </div>
            <div class="col-md-8 mb-3">
              <input type="text" class="form-control" id="lastName" v-model="lastName" :readonly="!profileEditMode">
            </div>
            <div class="col-md-4 mb-3">
              <label for="email">Email:</label>
            </div>
            <div class="col-md-8 mb-3">
              <input type="email" class="form-control" id="email" v-model="email" :readonly="!profileEditMode">
            </div>
            <div class="col-md-4 mb-3">
              <label for="phoneNumber">Teléfono:</label>
            </div>
            <div class="col-md-8 mb-3">
              <input type="text" class="form-control" id="phoneNumber" v-model="phoneNumber" :readonly="!profileEditMode">
            </div>
            <div class="col-md-4 mb-3">
              <label for="country">País:</label>
            </div>
            <div class="col-md-8 mb-3">
              <input type="text" class="form-control" id="country" v-model="country" :readonly="!profileEditMode">
            </div>
            <div class="col-md-4 mb-3">
              <label for="state">Estado:</label>
            </div>
            <div class="col-md-8 mb-3">
              <input type="text" class="form-control" id="state" v-model="state" :readonly="!profileEditMode">
            </div>
            <div class="col-md-4 mb-3">
              <label for="city">Ciudad:</label>
            </div>
            <div class="col-md-8 mb-3">
              <input type="text" class="form-control" id="city" v-model="city" :readonly="!profileEditMode">
            </div>
            <div class="col-md-4 mb-3">
              <label for="highSchool">Escuela:</label>
            </div>
            <div class="col-md-8 mb-3">
              <input type="text" class="form-control" id="highSchool" v-model="highSchool" :readonly="!profileEditMode">
            </div>
            <div class="col-md-4 mb-3">
              <label for="highSchool">Codigo de maestro:</label>
            </div>
            <div class="col-md-8 mb-3">
              <input type="text" class="form-control" id="tutor_code" v-model="tutor_code" :readonly="!profileEditMode">
            </div>
            
            
          </div>
          <div v-if="isSubscribed === 1" class="col-md-12 mb-3">
              <p class="text-muted">Próxima renovación automática: {{ formattedNextChargeDate }}</p>
            </div>
          <div v-if="!isMobile()">
            <div v-if="!profileEditMode">
            <button type="button" class="btn btn-secondary" style="margin-right: 10px;" @click="goToStudentDashboard">Regresar</button>
            <button type="button" class="btn btn-primary" style="margin-right: 10px; margin-left: 10px;" @click="editProfile">Editar perfil</button>
            <button type="button" class="btn btn-primary" style="margin-right: 10px; margin-left: 10px;;" @click="goToChangePassword">Cambiar contraseña</button>
            <button v-if="this.isSubscribed==1" class="btn btn-danger" @click="showCancelModalDialog">Cancelar suscripción</button>
                <button v-else class="btn btn-primary" @click="goToPaymentPlans">Suscribirme</button>
          </div>
            <div v-else>
                <button type="button" class="btn btn-primary" style="margin-right: 10px;" @click="saveProfile">Guardar perfil</button>
                <button type="button" class="btn btn-secondary" style="margin-left: 10px;" @click="cancelEdit">Cancelar</button>
            </div>
          </div>
          <div v-if="isMobile()">
            <div v-if="!profileEditMode">
            <button type="button" class="btn btn-primary" style="width: 60%; height: 40px;" @click="editProfile">Editar perfil</button>
            <div style="margin-top: 20px;">
            <button type="button" class="btn btn-primary" style="width: 60%; height: 40px;" @click="goToChangePassword">Cambiar contraseña</button>
            </div>
            <div style="margin-top: 20px;">
            <button v-if="this.isSubscribed==1" class="btn btn-danger" @click="showCancelModalDialog" style="min-width: 60%; height: 40px;">Cancelar suscripción</button>
            <button v-else class="btn btn-primary" @click="goToPaymentPlans" style="width: 60%; height: 40px;">Suscribirme</button>
            </div>
          </div>
            <div v-else class="d-flex flex-row justify-content-between">
                <button type="button" class="btn btn-primary" style="width: 45%; height: 40px;" @click="saveProfile">Guardar perfil</button>
                <button type="button" class="btn btn-secondary" style="width: 45%; height: 40px;" @click="cancelEdit">Cancelar</button>
            </div>
            <div>
              <button type="button" class="btn btn-secondary" style="width: 60%; height: 40px; margin-top: 20px;" @click="goToStudentDashboard">Regresar</button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="showCancelModal" class="modal-backdrop" @click="showCancelModal = false"></div>
    <div v-if="showCancelModal" class="modal" tabindex="-1" style="display: block;">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">Confirmar Cancelación</h5>
        <button type="button" class="btn-close" @click="showCancelModal = false"></button>
      </div>
      <div class="modal-body">
        <p>¿Estás seguro de que deseas cancelar tu suscripción?</p>
        <p>Perderás acceso a las simulaciones del examen y tu historial.</p>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" @click="showCancelModal = false">Cerrar</button>
        <button type="button" class="btn btn-danger" @click="cancelSubscription">Cancelar Suscripcion</button>
      </div>
    </div>
  </div>
</div>
      </div>
  </template>

<div class="text-center">
      </div>
  
  
  <script>
  import axios from "axios";
  import { mapState, mapActions } from "vuex";
  import { loadStripe } from '@stripe/stripe-js';

  
  export default {

    computed: {
  ...mapState(["email", "isSubscribed", "userId"]),

  formattedNextChargeDate() {
        if (this.nextChargeDate) {
          const date = new Date(this.nextChargeDate);
          const monthNames = [
            "Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio",
            "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"
          ];
          return `${monthNames[date.getMonth()]} ${date.getDate()} del ${date.getFullYear()}`;
        }
        return '';
      },
},

    data() {
      return {
        firstName: "",
        lastName: "",
        phoneNumber: "",
        country: "",
        state: "",
        city: "",
        highSchool: "",
        tutor_code: "",
        profileEditMode: false,
        showCancelModal: false,
        nextChargeDate: "",
        userImage: require('@/assets/user.png'),
        HeaderLogo: require('@/assets/Mathpaas-white.png')
      };
    },
    async created() {
      try {
        // Fetch the authenticated user's email
        const response = await axios.get(`${this.$baseURL}/api/users/user-data?email=${this.email}`);
  
        if (response.data.success) {
          const user = response.data.data;
          this.firstName = user.name.split(" ")[0];
          this.lastName = user.name.split(" ")[1];
          this.phoneNumber = user.phone;
          this.country = user.country;
          this.state = user.state;
          this.city = user.city;
          this.highSchool = user.school;
          this.tutor_code = user.tutor_code;
        }
      } catch (error) {
        console.error("Error fetching user data:", error);
      }

      try {
        const responseNextChargeDate = await axios.get(`${this.$baseURL}/api/next-charge-date?userId=${this.userId}`);
        this.nextChargeDate = responseNextChargeDate.data.nextChargeDate;
      } catch (error) {
        console.error("Error fetching next charge date:", error);
      }
    },

    methods: {

      isMobile() {
    return window.innerWidth <= 768; // Adjust this value based on your needs
  },

      ...mapActions(['updateSubscription']),

      goToPaymentPlans() {
    // Navigate to the Score Report History view
    this.$router.push("/payment-plan");
  },

      async subscribe() {
  const stripe = await loadStripe("pk_test_51MydE2DbP1recgpH0UVwVp9UqZdCuScevzZsMCOGGkLrKyDfU1x8nQuTt9djn4Bns9Hm4A02QLfhA4T39wLE5NKw00U3G5raSn");
  axios
    .post(`${this.$baseURL}/api/create-checkout-session`, {
      user_id: this.userId,
      email: this.email // Change this property name to match the backend
    }) 
    .then((response) => {
      const sessionId = response.data.sessionId;
      stripe.redirectToCheckout({ sessionId });
    })
    .catch((error) => {
      console.error('Error creating checkout session:', error);
    });
},

      async cancelSubscription() {
  try {
    const response = await axios.get(`${this.$baseURL}/api/users/subscription-data?user_id=${this.userId}`);

    if (response.data.success) {
      const subscriptionId = response.data.data.subscription_id;

      // Cancel the subscription by calling your backend API
      const cancelResponse = await axios.post(`${this.$baseURL}/api/subscriptions/cancel`, {
        subscription_id: subscriptionId,
        user_id: this.userId,
      });

      if (cancelResponse.data.success) {
        this.showCancelModal=false;
        this.updateSubscription(0);
        alert('Subscription canceled successfully');
      } else {
        alert('Error canceling subscription');
      }
    } else {
      alert('Error fetching subscription data');
    }
  } catch (error) {
    console.error('Error fetching subscription data:', error);
    alert('Error fetching subscription data');
  }
},


      showCancelModalDialog() {
    this.showCancelModal = true;
  },

    goToChangePassword() {
      this.$router.push("/change-password");
    },

    goToStudentDashboard() {
    this.$router.push("/student-dashboard");
  },

    editProfile() {
      this.profileEditMode = true;
    },

    cancelEdit() {
      this.profileEditMode = false;
    },

    async saveProfile() {

      try {
        await axios.put(`${this.$baseURL}/api/update-profile`, {
          first_name: this.firstName,
          last_name: this.lastName,
          email: this.email,
          phone_number: this.phoneNumber,
          country: this.country,
          state: this.state,
          city: this.city,
          high_school: this.highSchool,
          tutor_code: this.tutor_code,
        });

        this.profileEditMode = false;
        alert("Profile updated successfully");
      } catch (error) {
        console.error("Error updating profile:", error.message);
        alert("Error updating profile");
      }
    },
  },
};
</script>

<style>
body {
  background: #38b6ff;
}

.card {
      background-color: white;
    }

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1040;
  background-color: rgba(0, 0, 0, 0.5);
}

.modal-content {
  max-height: calc(100vh - 60px);
}

.modal-body {
  overflow-y: auto;
}
</style>

  