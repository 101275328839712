<template>
  <div class="container" style="height: 100vh;">
    <div class="header" style="margin-top: 10px;">
        <img :src="HeaderLogo" alt="HeaderLogo" class="header-logo"  />
        <router-link to="user-profile">
          <img :src="userImage" alt="User" class="user-image" />
        </router-link>
      </div>
    <div class="card-container" style="display: flex; justify-content: center; align-items: center;">
      <div class="card" style="width: 100%; height: 100%;">
      <div class="card-body">
        <h1 class="card-title" style="margin-top: 20px;">Suscripción activada exitosamente!</h1>
            <p>Ahora tendrás acceso a todos los beneficios de la plataforma.</p>

            <h3 class="card-subtitle mb-3" style="margin-top: 20px;">Los beneficios que incluye tu suscripción son:</h3>
            <div style="display: flex; justify-content: center;">
              <div style="text-align: left;">
                <p>- Ejercicios de práctica ilimitados</p>
                <p>- Tutor virtual de matemáticas</p>
                <p>- Simulador del examen</p>
                <p>- Historial de resultados</p>
                <p>- Métricas y consejos para mejorar</p>
              </div>
            </div>
            <p class="card-text" style="margin-top: 20px;">
              Tu suscripción se renovará automáticamente el: <strong>{{ formattedNextChargeDate }}</strong>
            </p>
        <router-link to="/student-dashboard" class="btn btn-primary" style="margin-top: 20px;">Regresar</router-link>
      </div>
    </div>
  </div>
    </div>
    
</template>

<script>
import { mapActions } from 'vuex';
import axios from 'axios'; 

import useFacebookPixel from '@/composables/useFacebookPixel';
  import { onMounted } from 'vue';


export default {
  setup() {
    const { track } = useFacebookPixel();

    onMounted(() => {
      track('Purchase', { value: 300.00, currency: 'MXN', content_name: 'Subscription Success' });
      track('Subscribe', { content_name: 'Subscription Success' });
    });

    return {};
  },  data() {
    return {
      nextChargeDate: '',
      userImage: require('@/assets/user.png'),
      HeaderLogo: require('@/assets/Mathpaas-white.png')
    };
  },
  computed: {
    formattedNextChargeDate() {
      if (this.nextChargeDate) {
        const date = new Date(this.nextChargeDate);
        const monthNames = ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'];
        return `${date.getDate()} de ${monthNames[date.getMonth()]} del ${date.getFullYear()}`;
      } else {
        return '';
      }
    }
  },
  mounted() {
    const email = this.$route.query.email;
    this.updateEmail(email);
    this.fetchNextChargeDate(this.$route.query.userId).then((date) => {
      this.nextChargeDate = date;
    });
  },
  methods: {
    ...mapActions(['updateEmail']),
    
    async fetchNextChargeDate(userId){
      try {
        const response = await axios.get(`${this.$baseURL}/api/next-charge-date?userId=${userId}`);
        const nextChargeDate = response.data.nextChargeDate;
        return nextChargeDate;
      } catch (error) {
        console.error(`Error fetching next charge date for user ${userId}:`, error);
        return null;
      }
    },
  },
};
</script>

<style>
.card {
      background-color: white;
    }
</style>
