<template>
    <div>
    <header class="header">
      <img src="@/assets/Mathpaas-white.png" alt="Logo" class="logo" />
      <nav>
        <button v-if="!isMobile()" class="nav-item" @click="scrollTo('section-2')">¿Qué es Mathpaas?</button>
        <button v-if="!isMobile()" class="nav-item" @click="scrollTo('section-5')">¿Cómo funciona?</button>
        <button class="signin-btn" @click="goToSignIn">Iniciar sesión</button>
      </nav>
    </header>
  
<section v-if="!isMobile()" class="section section-1" id="section-1">
  <div class="content">
    <div class="column left-column">
        <strong class="intro-text">Practica para el examen de admisión e ingresa a las mejores preparatorias y universidades de México</strong>      <button class="cta-button" @click="goToSignIn">Ingresar ahora</button>
      <strong class="sub-text">Prueba gratis las herramientas<br> de práctica hoy!</strong>
    </div>
    <div class="column right-column">
      <img src="@/assets/Mathpaas-white.png" alt="Logo" class="logo-column" />
      <p class="logo-text">Tu plataforma de práctica para el examen de admisión<br> a la preparatoria y la universidad
      </p>
    </div>
  </div>
</section>
<section v-if="isMobile()" class="section section-1" id="section-1">
  <div class="content">
    <div>
    <div>
        <img src="@/assets/Mathpaas-white.png" alt="mobile-Logo" class="mobile-logo-column" style="width: 60vw; height: auto;"/>
    </div>
    <div style="margin-bottom:50px">
        <p class="text-white">Tu plataforma de práctica para el examen de admisión<br> a la preparatoria y la universidad</p>
    </div>
    <div style="margin-bottom:20px">
        <strong class="text-white">Practica para el examen de admisión e ingresa a las mejores preparatorias y universidades de México</strong>
    </div>
    <div>
        <button class="cta-button-mobile" @click="goToSignIn">Ingresar ahora</button>
    </div>
    <div>
        <strong class="text-white">Prueba gratis las herramientas<br> de práctica hoy!</strong>
    </div>
    </div>
    
  </div>
</section>
  
<section v-if="!isMobile()" class="section section-2" id="section-2">
  <div class="content">
    <div class="image-container"></div>
    <div class="text-container">
      <p>MATHPAAS es una plataforma de ejercicios matemáticos diseñada específicamente para la práctica y preparación del estudiante para la PRUEBA DE APTITUDES ACADÉMICA (PAA) utilizada por las preparatorias y universidades más reconocidas del país como examen de admisión.</p>
      <p>La plataforma permite al estudiante practicar ejercicios de Aritmética, Álgebra, Geometría y Análisis de Datos y Probabilidad, realizar simulaciones del examen y obtener métricas que le indican qué tan preparado está y las áreas en las que debe enfocar su estudio para obtener un mejor puntaje en su examen de admisión.</p>
      <p>Además, la plataforma cuenta con tips para resolver los ejercicios durante la práctica y un asistente virtual que explica cómo solucionar los problemas en caso de ser necesario.</p>
    </div>
  </div>
</section>
<section v-else class="section section-2" id="section-2">
  <div class="content">
    <div class="text" style="margin-left: 20px; margin-right: 20px; text-align: left">
      <p>MATHPAAS es una plataforma de ejercicios matemáticos diseñada específicamente para la práctica y preparación del estudiante para la PRUEBA DE APTITUDES ACADÉMICA (PAA) utilizada por las preparatorias y universidades más reconocidas del país como examen de admisión.</p>
      <p>La plataforma permite al estudiante practicar ejercicios de Aritmética, Álgebra, Geometría y Análisis de Datos y Probabilidad, realizar simulaciones del examen y obtener métricas que le indican qué tan preparado está y las áreas en las que debe enfocar su estudio para obtener un mejor puntaje en su examen de admisión.</p>
      <p>Además, la plataforma cuenta con tips para resolver los ejercicios durante la práctica y un asistente virtual que explica cómo solucionar los problemas en caso de ser necesario.</p>
    </div>
  </div>
</section>

  
<section v-if="!isMobile()" class="section section-3" id="section-3">
  <div class="content">
    <div class="s3-column">
      <img src="../assets/pencil-icon.png" alt="Pencil Icon">
      <p>Mejora tus habilidades en<br> las herramientas de práctica</p>
    </div>
    <div class="s3-column">
      <img src="../assets/exam-icon.png" alt="Exam Icon">
      <p>Prueba tus conocimientos<br> en el simulador del examen</p>
    </div>
    <div class="s3-column">
      <img src="../assets/graph-icon.png" alt="Graph Icon">
      <p>Mide tu progreso y encuentra<br> tus áreas de oportunidad</p>
    </div>
  </div>
</section>
<section v-else class="section section-3" id="section-3">
  <div class="content" style="justify-content: center">
    <div>
        <div>
      <img src="../assets/pencil-icon.png" alt="Pencil Icon" style="height: 20vh; width: auto;">
      <p class="text-white">Mejora tus habilidades en<br> las herramientas de práctica</p>
    </div>
    <div>
      <img src="../assets/exam-icon.png" alt="Exam Icon" style="height: 20vh; width: auto;">
      <p class="text-white">Prueba tus conocimientos<br> en el simulador del examen</p>
    </div>
    <div>
      <img src="../assets/graph-icon.png" alt="Graph Icon" style="height: 20vh; width: auto;">
      <p class="text-white">Mide tu progreso y encuentra<br> tus áreas de oportunidad</p>
    </div>
    </div>
    
  </div>
</section>

  
<section v-if="!isMobile()" class="section section-4" id="section-4">
  <div class="content">
    <div class="s4-column text-column">
        <p>Las mejores preparatorias y universidades del país utilizan el PAA como su examen de admisión.</p>
      <ul>
        <li>Instituto Tecnológico y de Estudios Superiores de Monterrey</li>
        <li>Universidad de Monterrey</li>
        <li>Universidad Anáhuac</li>
        <li>Universidad Regiomontana</li>
        <li>Universidad de Guadalajara</li>
        <li>Benemérita Universidad Autónoma de Puebla</li>
      </ul>
      <p>El examen tiene un total de 1600 puntos y 800 corresponden a las pruebas de matemáticas. Los puntajes de admisión comienzan en 900 puntos y puedes obtener una beca a partir de los 1100 puntos.</p>
      <p>Con Mathpaas no solo podrás prepararte para obtener el mayor puntaje posible y ser admitido en la preparatoria o universidad de tu elección, si no que incluso podrás aspirar a una beca!</p>
    </div>
    <div class="s4-image-container"></div>
  </div>
</section>
<section v-else class="section section-4" id="section-4">
  <div class="content">
    <div class="text" style="margin-left: 20px; margin-right: 20px; text-align: left">
        <p>Las mejores preparatorias y universidades del país utilizan el PAA como su examen de admisión.</p>
      <ul>
        <li>Instituto Tecnológico y de Estudios Superiores de Monterrey</li>
        <li>Universidad de Monterrey</li>
        <li>Universidad Anáhuac</li>
        <li>Universidad Regiomontana</li>
        <li>Universidad de Guadalajara</li>
        <li>Benemérita Universidad Autónoma de Puebla</li>
      </ul>
      <p>El examen tiene un total de 1600 puntos y 800 corresponden a las pruebas de matemáticas. Los puntajes de admisión comienzan en 900 puntos y puedes obtener una beca a partir de los 1100 puntos.</p>
      <p>Con Mathpaas no solo podrás prepararte para obtener el mayor puntaje posible y ser admitido en la preparatoria o universidad de tu elección, si no que incluso podrás aspirar a una beca!</p>
    </div>
  </div>
</section>
  
<section v-if="!isMobile()" class="section section-5" id="section-5">
  <div class="content">
    <div class="s5-column">
      <div class="top-row">
        <img src="../assets/landing-s5-1.jpg" alt="Screen 1">
      </div>
      <div class="bottom-row">
        <img src="../assets/landing-s5-2.jpg" alt="Screen 2">
        <img src="../assets/landing-s5-3.jpg" alt="Screen 3">
      </div>
    </div>
    <div class="s5-text-column">
      <p>Practica problemas abiertos o de opción múltiple y prepárate para las diferentes pruebas del examen.</p>
      <p>Elige entre Aritmética, Álgebra, Geometría o la categoría de problemas en la que quieres enfocarte para mejorar.</p>
      <p>Las herramientas de práctica cuentan con pistas para ayudarte a resolver los problemas.</p>
      <p>Nuestro asistente virtual podrá responder cualquier duda que tengas en el chat para ayudarte a entender los ejercicios que no puedas resolver por tu cuenta.</p>
    </div>
  </div>
</section>
<section v-else class="section section-5" id="section-5">
  <div class="content">
    <div>
        <div>
            <img src="../assets/landing-s5-1.jpg" alt="mobile Screen 1" style="width: 90vw; height: auto;">
        </div>
        <div class="text-white" style="margin-left: 20px; margin-right: 20px; margin-top: 20px;text-align: left">
      <p>Practica problemas abiertos o de opción múltiple y prepárate para las diferentes pruebas del examen.</p>
      <p>Elige entre Aritmética, Álgebra, Geometría o la categoría de problemas en la que quieres enfocarte para mejorar.</p>
      <p>Las herramientas de práctica cuentan con pistas para ayudarte a resolver los problemas.</p>
      <p>Nuestro asistente virtual podrá responder cualquier duda que tengas en el chat para ayudarte a entender los ejercicios que no puedas resolver por tu cuenta.</p>
    </div>
    </div>
    
  </div>
</section>


  
<section v-if="!isMobile()" class="section section-6" id="section-6">
  <div class="content">
    <div class="s6-text-column">
      <p>Realiza simulaciones de la Prueba de Aptitudes Matemáticas y pon a prueba tus habilidades.</p>
      <p>Aprende a manejar tu tiempo, conoce el tipo de problemas en cada prueba y familiarízate con el examen.</p>
      <p>Podrás revisar tus puntuaciones por examen, sección o categoría en el historial.</p>
      <p>Accede a tus exámenes anteriores y utiliza las pistas o pídele ayuda al asistente virtual para entender los problemas que no pudiste solucionar para obtener un mejor puntaje en tu próximo intento.</p>
    </div>
    <div class="s6-column">
      <div class="top-row">
        <img src="../assets/landing-s6-1.png" alt="Screen 1">
      </div>
      <div class="bottom-row">
        <img src="../assets/landing-s6-2.png" alt="Screen 2">
        <img src="../assets/landing-s6-3.png" alt="Screen 3">
      </div>
    </div>
  </div>
</section>
<section v-else class="section section-6" id="section-6">
  <div class="content">
    <div>
        <div>
            <img src="../assets/landing-s6-1.png" alt="mobile Screen 4" style="width: 90vw; height: auto;">
        </div>
        <div class="text-white" style="margin-left: 20px; margin-right: 20px; margin-top: 20px;text-align: left">
      <p>Realiza simulaciones de la Prueba de Aptitudes Matemáticas y pon a prueba tus habilidades.</p>
      <p>Aprende a manejar tu tiempo, conoce el tipo de problemas en cada prueba y familiarízate con el examen.</p>
      <p>Podrás revisar tus puntuaciones por examen, sección y categoría en el historial.</p>
      <p>Accede a tus exámenes anteriores y utiliza las pistas o pídele ayuda al asistente virtual para entender los problemas que no pudiste solucionar para obtener un mejor puntaje en tu próximo intento.</p>
    </div>
    </div>
  </div>
</section>

  
<section v-if="!isMobile()" class="section section-7" id="section-7">
  <div class="content">
    <div class="column single-column">
    <div>
      <img src="@/assets/Mathpaas-white.png" alt="Logo" class="logo-column" />
      <p class="logo-text">Tu plataforma de práctica para el PAA</p>    </div>
    <div>
        <button class="cta-button" @click="goToSignIn">Ingresar ahora</button>
    </div>
    <div>
        <strong class="sub-text">Obtén acceso ilimitado a las herramientas de práctica y simulaciones del examen desde $300 MXN al mes!</strong>
    </div>
    </div>
  </div>
</section>
<section v-else class="section section-7" id="section-7">
  <div class="content">
    <div class="column single-column">
    <div style="margin-bottom:50px">
      <img src="@/assets/Mathpaas-white.png" alt="mobile-Logo" style="width:60vw; height:auto;"/>
      <p class="text-white">Tu plataforma de práctica para el PAA</p>    </div>
    <div>
        <button class="cta-button-mobile" @click="goToSignIn">Ingresar ahora</button>
    </div>
    <div style="margin-left: 20px; margin-right: 20px;">
        <strong class="text-white">Obtén acceso ilimitado a las herramientas de práctica y simulaciones del examen desde $300 MXN al mes!</strong>
    </div>
    </div>
  </div>
</section>
  
      <footer class="footer">
        <!-- Footer content -->
        <div class="d-flex justify-content-evenly text-white" style="padding-top: 20px;">
          <div>
            <p>Contaco: contacto@mathpaas.com o +52-811-0668-259 </p>
          </div>
          <div>
            <a href="https://www.mathpaas.com/terms-conditions" target="_blank">términos y condiciones </a>
          </div>
          <div>
            <a href="https://www.mathpaas.com/privacy-advice" target="_blank">aviso de privacidad</a>
          </div>
        </div>
      </footer>
    </div>
  </template>
  
  <script>
  
  import useFacebookPixel from '@/composables/useFacebookPixel';
  import { onMounted } from 'vue';


export default {
  setup() {
    const { track } = useFacebookPixel();

    onMounted(() => {
      track('ViewContent', { content_name: 'Landing Page' });
    });

    return {};
  },    name: 'LandingPage',
    components: {
      // Add your components here
    },
    data() {
      return {
        // Your data here
      };
    },
    methods: {

        scrollTo(id) {
        const element = document.getElementById(id);
        element.scrollIntoView({ behavior: 'smooth' });
        },

        goToSignIn() {
      this.$router.push('/signin');
    },

        isMobile() {
    return window.innerWidth <= 768; // Adjust this value based on your needs
  },
    },
  }
  </script>
  
  <style scoped>
  .header {
    margin-top: -10px;
    position: fixed;
    width: 100%;
    height: 60px;
    background: #36b5ff;
    z-index: 100;
  }

  .logo {
  height: 30px;
  margin-left: 20px;
  margin-top: 15px;
  margin-bottom:15px;
}

.nav-item {
  margin-right: 20px;
  background: none;
  border: none;
  color: white;
  cursor: pointer;
}

.signin-btn {
  background: white;
  color: #36b5ff;
  border: none;
  height: 30px;
  padding-right:10px;
  padding-left: 10px;
  border-radius: 4px;
  cursor: pointer;
  margin-right: 20px;
}
  
  .section {
    height: 100vh;
    width: 100%;
  }

  .content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
}

.column {
  flex: 1;
}

.left-column {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-left: 50px;
}

.right-column {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.intro-text, .sub-text {
  margin-bottom: 20px;
  font-size:28px;
  color: white;
}

.logo-text {
  margin-bottom: 20px;
  font-size:23px;
  color: white;
}

.cta-button {
  background-color: #5ce1e6;
  font-size: 36px;
  color: white;
  border: none;
  margin-bottom: 20px;
  padding: 10px 50px;
  border-radius: 50px;
  cursor: pointer;
}

.cta-button-mobile {
  background-color: #5ce1e6;
  font-size: 20px;
  color: white;
  border: none;
  margin-bottom: 20px;
  padding: 10px 50px;
  border-radius: 50px;
  cursor: pointer;
}

.logo-column {
  height: 85px;
  margin-bottom: 10px;
}
  
  .section-1 {
    background-color: #36b5ff;
  }
  
  .section-2 {
  background-color: white;
  display: flex;
}

.image-container {
  flex: 1;
  background: url('../assets/landing-s2.jpg') no-repeat center;
  background-size: cover;
  height: 100vh;
  max-width: 40%;
}

.text-container {
  flex: 1;
  padding: 50px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: left;
  font-size: 28px;
}
  
  .section-3 {
    background-color: #ff914d;
    padding-left:50px;
    padding-right:50px;
  }

.s3-column {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

}

.s3-column img {
  width: 150px; /* Adjust the size as needed */
  height: 150px; /* Adjust the size as needed */
  margin-bottom: 20px;
}

.s3-column p {
  color: white;
  font-size: 28px; /* Adjust the size as needed */
}
  

  .section-4 {
  background-color: white;
  display: flex; 
  flex-direction: row-reverse; 
}

.s4-image-container {
  flex: 1;
  background: url('../assets/landing-s4.jpg') no-repeat center;
  background-size: cover;
  height: 100vh;
  max-width: 40vw;
}

.text-column {
  flex: 1;
  padding: 50px;
}

.text-column p {
  font-size: 28px; /* Adjust as per your needs */
  text-align: left;
  color: #000; /* Adjust as per your needs */
}

.text-column ul {
  list-style: none;
  padding: 0;
  font-size: 23px; /* Adjust as per your needs */
  text-align: left;
  color: #000;
}

.text-column ul li::before {
  content: "• ";
  color: #000; /* Adjust as per your needs */
}

.section-5 {
  background-color: #7ed957;
  display: flex;
}

.s5-column {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 60vw;
}

.top-row, .bottom-row {
  display: flex;
  justify-content: space-around;
  padding-left: 30px;
}

.top-row img {
  width: 100%;
  height: auto;
  margin-bottom: 20px;
  padding-left: 20px;
}

.bottom-row img {
  width: 50%;
  height: auto;
  padding-left:20px;
}


.s5-text-column {
  flex: 1;
  padding: 50px;
  font-size: 28px;
  color: white;
  text-align: left;
  max-width: 40vw;
}


  .section-6 {
  background-color: #ff66c4;
  display: flex;
}

.s6-column {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 60vw;
}

.s6-column .top-row, .s6-column .bottom-row {
  display: flex;
  justify-content: space-around;
  padding-right: 30px;
}

.s6-column .top-row img {
  width: 100%;
  height: auto;
  margin-bottom: 20px;
  padding-right: 20px;
}

.s6-column .bottom-row img {
  width: 50%;
  height: auto;
  padding-right:20px;
}

.s6-text-column {
  flex: 1;
  padding: 50px;
  font-size: 28px;
  color: white;
  text-align: left;
  max-width: 40vw;
}

  
  .section-7 {
    background-color: #36b5ff;
    display: flex;
    justify-content: center;
  }


.section-7 .single-column {
  max-width: 800px;  /* Adjust as needed */
}

  
  .footer {
    height: 60px;
    background-color: black;
    color: white;
  }
  </style>
  